














import { defineComponent, reactive } from "@vue/composition-api";
import Avatar from "vue-avatar";
export default defineComponent({
  name: "Security",
  props: {},
  components: {
    Avatar
  },
  setup(props) {
    const state = reactive({});

    return {
      state,
      props
    };
  }
});
