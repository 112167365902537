























































































































import TitleBar from "@/components/globalFrontendComponents/TitleBar.vue";
import Dropzone from "vue2-dropzone";
import VuePhoneNumberInput from "vue-phone-number-input";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
} from "@vue/composition-api";
import { getPhoneInputOptions } from "@/helpers/phone-input";
import router from "@/router";
import store from "@/store";
import { getDropZoneOptions } from "@/helpers";
import { GqlAddPromotionInput } from "@/helpers/gql-schema";
import { addDays, format } from "date-fns";
import PromotionDurationInput from "@/components/Forms/PromotionDurationInput.vue";
import LoadingScreen from "@/components/LoadingScreen.vue";
import { ApprovedTransaction, PromotionPriceDetails } from "@/helpers/types";
import Swal from "sweetalert2";
const fedaPayPublicKey = process.env.VUE_APP_FEDAPAY_PUBLIC_KEY;

export default defineComponent({
  name: "LaunchPromotion",
  components: {
    TitleBar: TitleBar,
    Dropzone,
    VuePhoneNumberInput,
    PromotionDurationInput,
    LoadingScreen,
  },
  setup(_props, context) {
    const promotionPhotoDropzone = ref(null);
    const todayDate = new Date();

    const state = reactive({
      dataLoadingComplete: false,
      uploadComplete: false,
      uploadFailed: false,
      uploadedPictureGalleryId: null as number | null,
      promotionText: "",
      promotionPriceDetails: {
        dayCount: 1,
        price: computed(
          (): number => store.state.common.platformSetup?.monthlyFee ?? 0
        ),
      } as PromotionPriceDetails,
      promotionDetails: {
        startDate: format(todayDate, "dd/MM/yyyy"),
        endDate: computed((): string =>
          format(
            addDays(todayDate, state.promotionPriceDetails.dayCount),
            "dd/MM/yyyy"
          )
        ),
        transactionId: undefined as any,
      },
      validator: {
        isValidPromotionText: computed(
          (): boolean => state.promotionText.length > 3
        ),
        canSubmit: computed((): boolean => {
          return state.validator.isValidPromotionText;
        }),
      },
      isLoading: false,
      currentProcessMessage: "AJOUTER",
    });

    const phoneInputOptions = getPhoneInputOptions();
    const dropzoneOptions = getDropZoneOptions();

    onMounted(async () => {
      if (!store.state.users.currentUser) {
        store.commit.common.SET_WAITING_FOR_LOGIN_ROUTE("AddListing");
        void router.push("/session/login");
        return;
      }

      if (store.state.users.currentUser.shops.length === 0)
        await store.dispatch.users.fetchUserDetails();

      await store.dispatch.common.fetchCategories();
      await store.dispatch.common.fetchCities();
      await store.dispatch.common.loadPlatformSetup();

      state.dataLoadingComplete = true;
    });

    async function launchPaymentProcess(): Promise<ApprovedTransaction> {
      const FedaPay = (window as any).FedaPay;

      try {
        const completedTransactionId = await new Promise((resolve, reject) => {
          const { currentUser } = store.state.users;

          if (!currentUser) return reject("No logged user found");

          const initOptions = {
            public_key: fedaPayPublicKey,
            transaction: {
              amount: state.promotionPriceDetails.price,
              description: "Frais de promotion",
            },
            currency: {
              iso: "XOF",
            },
            customer: {
              firstname: currentUser.name ?? "",
              lastname: currentUser.firstName ?? "",
              phone_number: {
                country: "BJ",
              },
            },
            onComplete: (result: { reason: string; transaction: any }) => {
              if (result.reason === "CHECKOUT COMPLETE") {
                resolve(result.transaction.id);
              } else {
                reject(result.reason);
              }
            },
          };

          FedaPay.init(initOptions).open();
        });

        const paymentReallyCompleted =
          await store.dispatch.common.verifyPayment(
            completedTransactionId as number
          );

        if (!paymentReallyCompleted)
          throw new Error("Transaction not completed");

        return paymentReallyCompleted;
      } catch (error) {
        return {
          approved: false,
        };
      }
    }

    async function uploadPictures() {
      if (!promotionPhotoDropzone) return;
      (promotionPhotoDropzone.value as any).processQueue();

      try {
        await new Promise((resolve, reject) => {
          const interval = setInterval(() => {
            if (state.uploadComplete) {
              resolve(state.uploadedPictureGalleryId);
              return;
            }
            if (state.uploadFailed) {
              clearInterval(interval);
              reject("Upload failed");
            }
          }, 1000);
        });
        return true;
      } catch (error) {
        return false;
      }
    }

    async function launchPromotion() {
      if (state.isLoading) return;
      state.isLoading = true;
      if ((promotionPhotoDropzone.value as any).getAcceptedFiles().length < 1) {
        context.root.$notify({
          group: "launchPromotionNotifs",
          type: "warning",
          title: "Oupss",
          text: "Veuillez fournir au moins une photo",
        });
        state.isLoading = false;
        return;
      }

      state.currentProcessMessage = "Téléchargement de vos photos";
      const pictureUploaded = await uploadPictures();
      state.currentProcessMessage = "Téléchargement terminées";

      if (!pictureUploaded) {
        context.root.$notify({
          group: "launchPromotionNotifs",
          type: "error",
          title: "Oupss",
          text: "Une erreur est survenue lors de l'envoi des fichiers",
        });
        state.isLoading = false;
        state.currentProcessMessage = "AJOUTER";
        return;
      }

      state.currentProcessMessage = "En attente du paiement";
      const paymentResult = await launchPaymentProcess();
      state.currentProcessMessage = "Ajout en cours";

      console.log("=============== paymentResult:", paymentResult);

      if (
        !paymentResult.approved ||
        !paymentResult.receiptUrl ||
        !paymentResult.amount
      ) {
        context.root.$notify({
          group: "launchPromotionNotifs",
          type: "error",
          title: "Erreur",
          text: "La transaction n'a pas été complétée",
        });
        state.isLoading = false;
        state.currentProcessMessage = "AJOUTER";
        return;
      }

      state.promotionDetails.transactionId = paymentResult.transactionId;

      const payload: GqlAddPromotionInput = {
        startDate: state.promotionDetails.startDate,
        endDate: state.promotionDetails.endDate,
        shopId: store.state.users.currentUser!.shops[0].id,
        promotionText: state.promotionText,
        galleryId: state.uploadedPictureGalleryId!.toString(),
        price: state.promotionPriceDetails.price,
        transactionId: state.promotionDetails.transactionId,
      };

      const promotionLaunchResult = await store.dispatch.users.launchPromotion(
        payload
      );

      if (!promotionLaunchResult.success) {
        context.root.$notify({
          group: "launchPromotionNotifs",
          type: "error",
          title: "Erreur",
          text: promotionLaunchResult.message,
        });
        state.isLoading = false;
        state.currentProcessMessage = "AJOUTER";
      } else {
        await Swal.fire({
          title: "Terminé",
          text: promotionLaunchResult.message,
          icon: "success",
          confirmButtonText: "OK",
        });
        void router.replace("/mon-profil");
      }
    }

    function onMultiUploadComplete(files: any, response: any) {
      state.uploadComplete = true;
      state.uploadedPictureGalleryId = response;
    }

    function onMultiUploadError(files: any, message: string, xhr: any) {
      state.uploadFailed = true;
      console.error(message);
    }

    function onPromotionPriceDetailsChange(details: PromotionPriceDetails) {
      state.promotionPriceDetails = details;
    }

    return {
      state,
      phoneInputOptions,
      dropzoneOptions,
      launchPaymentProcess,
      promotionPhotoDropzone,
      onMultiUploadComplete,
      onMultiUploadError,
      launchPromotion,
      onPromotionPriceDetailsChange,
    };
  },
});
