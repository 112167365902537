






























import { ManagerState } from "@/store/common";
import { defineComponent, onMounted, PropType } from "@vue/composition-api";
import Avatar from "vue-avatar";

export default defineComponent({
  name: "ShopDetailsSideBar",
  props: {
    manager: {
      required: true,
      type: Object as PropType<ManagerState>
    }
  },
  components: {
    Avatar
  },
  setup(props) {
    return {
      props
    };
  }
});
