import { graphqlRequest } from "@/helpers/graphql-client";
import { getToken } from ".";

let token: string | undefined;
export const apiUrl: string = process.env.VUE_APP_BACKEND_API_GRAPHQL_URL;
export async function dataApiRequest(query: string, variables?: any) {
  return await graphqlRequest({
    query,
    variables,
    authToken: getToken()
  });
}
