




































































import store from "@/store";
import {
  computed,
  defineComponent,
  reactive,
  Ref,
  ref,
  onMounted,
  watch
} from "@vue/composition-api";
import { PlatformSetup } from "shared-dependancies";

type PlatformFeatures = {
  desc: string;
  icon: string;
  id: string;
  name: string;
};

export default defineComponent({
  name: "ReferralProgram",
  props: {
    title: {
      required: true,
      type: String
    },
    desc: {
      required: true,
      type: String
    }
  },
  setup(props, context) {
    const state = reactive({
      platformSetup: computed(
        (): PlatformSetup => store.state.common.platformSetup
      ),
      features: computed((): PlatformFeatures[] => []),
      platformSetupLoaded: computed((): boolean => state.platformSetup != null)
    });

    watch(state.platformSetup, () => {
      if (state.platformSetup.referralPercent !== null)
        state.features.push({
          desc: `En parrainant un nouvel utilisateur, vous gagner ${state.platformSetup.referralPercent}% de commission sur les frais de son premier abonnement.`,
          icon: "fa fa-dollar",
          id: "1",
          name: `${state.platformSetup.referralPercent}%`
        });

      if (state.platformSetup.minimumAmountToWithdral !== null)
        state.features.push({
          desc: `Dès que vous accumulez ${state.platformSetup.minimumAmountToWithdral} FCFA de commission sur votre compte, vous pouvez demander un retrait via Mobile Money.`,
          icon: "fa fa-calendar",
          id: "2",
          name: `Paiement à partir de ${state.platformSetup.minimumAmountToWithdral} FCFA`
        });
    });

    return { props, state };
  }
});
