














































import store from "@/store";
import { computed, defineComponent, reactive, ref } from "@vue/composition-api";

export default defineComponent({
  name: "MyPricing",
  props: {},
  components: {},
  setup(props, context) {
    const currentSubscriptionFee = computed(
      (): number | null => store.state.common.platformSetup.monthlyFee
    );

    return {
      currentSubscriptionFee
    };
  }
});
