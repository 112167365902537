
























import { TransferCanalType } from "shared-dependancies";
import { defineComponent, reactive } from "@vue/composition-api";

export default defineComponent({
  name: "TransferCanalChooser",
  props: {},
  components: {},
  setup(_props, context) {
    const state = reactive({
      currentValue: null as null | TransferCanalType
    });

    function onChange(value: TransferCanalType) {
      state.currentValue = value;
      context.emit("valueChange", value);
    }

    return {
      state,
      onChange
    };
  }
});
