







































































import eventBus from "@/event-bus";
import {
  getWindowWidth,
  MOBILE_DEVICE_WIDTH,
  TABLET_DEVICE_WIDTH
} from "@/helpers";
import store from "@/store";
import {
  computed,
  defineComponent,
  onMounted,
  reactive
} from "@vue/composition-api";
import Menu from "Components/Menu/Menu.vue";
import { $ } from "@/main";
export default defineComponent({
  name: "AppHeader",
  components: {
    appMenu: Menu
  },
  setup() {
    const state = reactive({
      showMenu: true,
      showAppMenu: false,
      canAddService: computed((): boolean =>
        store.state.users.currentUser != null
          ? store.state.users.currentUser.shopCount === 0
          : false
      )
    });

    onMounted(() => {
      // eventBus.$on("loadedMapsPage", () => (state.showMenu = false));
      eventBus.$on("leavingMapsPage", () => (state.showMenu = true));

      $("#navbar_toggler, .navbar-toggler").click(() => {
        $("#navbar_global").toggleClass("show");
      });
    });

    function closeAppMenu() {
      if (
        getWindowWidth() <= MOBILE_DEVICE_WIDTH ||
        getWindowWidth() <= TABLET_DEVICE_WIDTH
      )
        state.showAppMenu = !state.showAppMenu;
    }

    return {
      state,
      closeAppMenu
    };
  }
});
