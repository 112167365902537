<!-- Header Structure -->
<template>
  <div id="footer" class="sticky-footer bg-primary pt-5">
    <!-- Main -->
    <div class="container">
      <div class="row">
        <div class="col-md-5 col-sm-6">
          <img
            class="footer-logo"
            src="/static/images/logo-kokoko-entier.png"
            width="180"
            alt=""
          />
          <br /><br />
          <div class="text-widget text-white">
            <!-- <span class="text-white">12345 Little Lonsdale St, Melbourne</span> -->
            <br />
            Phone: <span class="text-white">(+229) 98 98 98 98</span><br />
            E-Mail:<span class="text-white">
              <a href="#" class="text-white">contact@kokoko.com</a> </span
            ><br />
          </div>
          <div class="mt-4">
            <!-- <a
              target="_blank"
              href="https://twitter.com/"
              class="btn btn-neutral btn-icon-only btn-round btn-lg"
              data-toggle="tooltip"
              data-original-title="Follow us"
            >
              <i class="fa fa-twitter"></i>
            </a> -->
            <a
              target="_blank"
              href="https://www.facebook.com/"
              class="btn btn-neutral btn-icon-only btn-round btn-lg"
              data-toggle="tooltip"
              data-original-title="Like us"
            >
              <i class="fa fa-facebook-square"></i>
            </a>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 ">
          <ul class="footer-links">
            <li>
              <router-link class="text-white" to="/session/login"
                >La carte</router-link
              >
            </li>
            <li>
              <router-link class="text-white" to="/register"
                >Ajouter votre service</router-link
              >
            </li>
          </ul>
        </div>
        <div class="col-md-4 col-sm-6 ">
          <ul class="footer-links">
            <li>
              <router-link class="text-white" to="/session/login"
                >Connexion</router-link
              >
            </li>
            <li>
              <router-link class="text-white" to="/register"
                >S'inscrire</router-link
              >
            </li>
            <!-- <li>
              <router-link class="text-white" to="/session/forgot-password"
                >Forgot Password</router-link
              >
            </li>
            <li>
              <router-link class="text-white" to="/pages/add-listing"
                >Add Listing</router-link
              >
            </li>
            <li>
              <router-link class="text-white" to="/pages/pricing"
                >Pricing</router-link
              >
            </li>
            <li>
              <router-link class="text-white" to="/pages/about-us"
                >About</router-link
              >
            </li> -->
          </ul>
        </div>
        <!-- <div class="col-md-3  col-sm-12">
          <ul class="footer-links">
            <li>
              <router-link class="text-white" to="/mon-profil"
                >Profile</router-link
              >
            </li>
            <li>
              <router-link class="text-white" to="/pages/blog/list"
                >Blog</router-link
              >
            </li>
            <li>
              <router-link class="text-white" to="/pages/invoice"
                >Invoice</router-link
              >
            </li>
            <li>
              <router-link class="text-white" to="/pages/booking"
                >Booking</router-link
              >
            </li>
            <li>
              <router-link class="text-white" to="/pages/contact-us"
                >Contact</router-link
              >
            </li>
          </ul>
        </div> -->
      </div>
      <!-- Copyright -->
      <div class="row">
        <div class="col-md-12">
          <div class="copyrights text-white">
            © 2019 KOKOKO. Tout droits réservés.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
