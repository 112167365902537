type MenuName =
  | "La carte"
  | "Mon profil"
  | "Session"
  | "Connexion"
  | "Inscription"
  | "Mot de passe oublié"
  | "Déconnexion";

export interface Menu {
  state: string;
  name: MenuName;
  type: "link" | "sub" | "button";
  children?: Menu[];
}

export const subMenuItemsMaps = {
  Connexion: "/session/login",
  Inscription: "register",
  "Mot de passe oublié": "/session/forgot-password",
  Déconnexion: "#"
};

export const menus: Menu[] = [
  {
    state: "/artisans-a-proximite",
    name: "La carte",
    type: "link"
  },
  {
    state: "/mon-profil",
    name: "Mon profil",
    type: "link"
  },
  {
    state: "/session",
    name: "Session",
    type: "sub",
    children: [
      { state: subMenuItemsMaps["Connexion"], name: "Connexion", type: "link" },
      {
        name: "Inscription",
        state: subMenuItemsMaps["Inscription"],
        type: "link"
      },
      {
        name: "Mot de passe oublié",
        state: subMenuItemsMaps["Mot de passe oublié"],
        type: "link"
      },
      {
        name: "Déconnexion",
        state: subMenuItemsMaps["Déconnexion"],
        type: "button"
      }
    ]
  }
];

export function getMenu(isUserLogged: boolean) {}

// export default menus;
