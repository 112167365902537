




































import { SubscriptionPriceDetails } from "@/helpers/types";
import store from "@/store";
import {
  computed,
  defineComponent,
  reactive,
  watch
} from "@vue/composition-api";

export default defineComponent({
  name: "SubscriptionInput",
  props: {
    label: {
      type: String,
      required: true
    }
  },
  setup(props, context) {
    const state = reactive({
      multiplier: 1,
      detailsText: computed(
        (): string =>
          `${state.multiplier} Mois (${state.currentSubscriptionPrice *
            state.multiplier} FCFA)`
      ),
      canReduceMultiplier: computed((): boolean => state.multiplier > 1),
      currentSubscriptionPrice: computed(
        (): number => store.state.common.platformSetup?.monthlyFee ?? 0
      )
    });

    watch(() => state.multiplier, onSubscriptionPriceDetailsChange);

    function onSubscriptionPriceDetailsChange() {
      context.emit("subscriptionPriceDetailsChange", {
        monthCount: state.multiplier,
        price: state.multiplier * state.currentSubscriptionPrice
      } as SubscriptionPriceDetails);
    }

    return {
      props,
      state
    };
  }
});
