





































































































































































import Vue from "vue";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch
} from "@vue/composition-api";
import GallerySlider from "@/components/globalFrontendComponents/GallerySlider.vue";
import SidebarLayoutTwo from "@/views/listing/SidebarLayoutTwo.vue";
import { FullShopInfo } from "@/store/common";
import store from "@/store";
import router from "@/router";
export default defineComponent({
  name: "ShopDetails",
  props: {
    shopId: {
      required: true,
      type: String
    }
  },
  components: {
    GallerySlider,
    SidebarLayoutTwo
  },
  setup(props, context) {
    const shopDescription = ref(null);
    const promotionText = ref(null);
    const state = reactive({
      shopDetails: undefined as FullShopInfo | undefined,
      shopGalleryPictures: computed(
        (): any[] => state.shopDetails?.gallery.pictures ?? []
      ),
      shopPictures: computed((): { url: string | null }[] => {
        return (state.shopDetails?.gallery.pictures ?? []).concat(
          state.shopDetails?.currentlyActivePromotion?.gallery.pictures ?? []
        );
      })
    });

    onMounted(async () => {
      // window.scrollTo(0, 0);
      if (props.shopId) {
        await fetchShopDetails(props.shopId);
        if (shopDescription.value)
          (shopDescription.value as any).innerHTML =
            state.shopDetails?.description;
        if (promotionText.value)
          (promotionText.value as any).innerHTML =
            state.shopDetails?.currentlyActivePromotion.promotionText;
      } else {
        router.replace("/artisans-a-proximite");
      }
    });

    watch(() => props.shopId, fetchShopDetails);

    function launchPhoneCall(phoneNumber: any) {
      if (phoneNumber) window.open(`tel:${phoneNumber}`);
    }

    async function fetchShopDetails(shopId: string) {
      const shopInfo = await store.dispatch.common.fetchShopDetails(shopId);
      if (shopInfo) state.shopDetails = shopInfo;
    }

    function findAWay() {
      const googleMapsDirectionUrl = `https://www.google.com/maps/dir/?api=1&destination=${state.shopDetails?.location.lat},${state.shopDetails?.location.lng}`;
      location.href = googleMapsDirectionUrl;
    }

    return { state, findAWay, launchPhoneCall, shopDescription };
  }
});
