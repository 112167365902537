

























































































import { GqlCategory, GqlCity } from "@/helpers/gql-schema";
import router from "@/router";
import store from "@/store";
import { CategoryState, CityState } from "@/store/common";
import {
  computed,
  defineComponent,
  onMounted,
  reactive
} from "@vue/composition-api";

export default defineComponent({
  name: "Banner",
  props: {
    title: {
      required: true,
      type: String
    },
    desc: {
      required: true,
      type: String
    },
    "bg-image-url": {
      required: true,
      type: String
    }
  },
  setup() {
    const state = reactive({
      categories: computed(
        (): CategoryState[] => store.state.common.categories
      ),
      cities: computed((): CityState[] => store.state.common.cities),

      filterParams: {
        categoryId: null as CategoryState | null,
        cityId: null as CityState | null
      }
    });

    onMounted(() => {
      store.dispatch.common.fetchCategories();
      store.dispatch.common.fetchCities();
    });

    function goToApp() {
      const { filterParams } = state;
      if (!filterParams.categoryId) void router.push("/app");

      void router.push(
        `/artisans-a-proximite?categoryFilter=${filterParams.categoryId}`
      );
    }

    return {
      state,
      goToApp
    };
  }
});
