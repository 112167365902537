







































































import router from "@/router";
import store from "@/store";
import { DisplayableShop } from "@/store/common";
import {
  computed,
  defineComponent,
  onMounted,
  reactive
} from "@vue/composition-api";
import Slick from "vue-slick";
import { Carousel, Slide } from "vue-carousel";
import { getCarouselOptions, getSlickOptions } from "@/helpers";

export default defineComponent({
  name: "MostVisitedPlaces",
  props: {
    title: {
      required: true,
      type: String
    },
    desc: {
      required: true,
      type: String
    }
  } as any,
  components: {
    Slick,
    Carousel,
    Slide
  },
  setup(props, context) {
    const state = reactive({
      slickOptions: getSlickOptions(),
      featuredShops: computed(
        (): DisplayableShop[] => store.state.common.featuredShops
      ),
      carouselOptions: getCarouselOptions()
    });

    onMounted(async () => {
      store.dispatch.common.fetchFeaturedShops();
    });

    function goToShop(shopId: string) {
      router.push("/shop/details/" + shopId);
    }

    return {
      state,
      props,
      goToShop
    };
  }
});
