













































import store from "@/store";
import { Notification } from "@/store/user";
import { computed, defineComponent, reactive } from "@vue/composition-api";
import Avatar from "vue-avatar";
import Vodal from "vodal";
import SubscriptionInput from "@/components/Forms/SubscriptionInput.vue";
import { GqlNotificationPriority } from "@/helpers/gql-schema";

export default defineComponent({
  name: "Notification",
  props: {},
  components: {
    Avatar,
    Vodal,
    SubscriptionInput
  },
  setup(_props, _context) {
    const state = reactive({
      userProfileNotifications: computed(
        (): Notification[] =>
          store.state.users.currentUser?.account?.notifications ?? []
      )
    });

    function notificationPriorityToUserReadable(
      priority: GqlNotificationPriority
    ) {
      return { INFO: "INFORMATION", WARNING: "RAPPEL", DANGER: "CAPITALE" }[
        priority
      ];
    }

    return {
      state,
      notificationPriorityToUserReadable
    };
  }
});
