<template>
  <div>
    <!-- Banner -->
    <banner-version-one
      :title="data.bannerTitle"
      :desc="data.bannerDesc"
      :bg-image-url="data.bannerImage"
    ></banner-version-one>

    <!-- Popular Categories -->
    <popular-categories
      :title="data.popularCategoriesTitle"
      :desc="data.popularCategoriesDesc"
      :data="data.categories"
    ></popular-categories>

    <!-- Most Visited Places -->
    <most-visited-places
      :title="data.mostVisitedPlacesTitle"
      :desc="data.mostVisitedPlacesDesc"
    ></most-visited-places>

    <!-- Services Section -->
    <services-section
      :title="data.servicesTitle"
      :desc="data.servicesDesc"
      :data="data.services"
    ></services-section>

    <!-- Pricing Tables  -->
    <pricing id="pricing"></pricing>

    <!-- Feature Grid Section -->
    <referral-program
      :title="data.featureGridTitle"
      :desc="data.featureGridDesc"
    >
    </referral-program>

    <!-- Team Section -->
    <team
      :title="data.teamSectionTitle"
      :desc="data.teamSectionDesc"
      :data="data.teamMembers"
    ></team>
  </div>
</template>

<script>
import Banner from "Components/globalFrontendComponents/Banner";
import PopularCategories from "Components/globalFrontendComponents/PopularCategories";
import MostVisitedPlaces from "Components/globalFrontendComponents/MostVisitedPlaces";
import Services from "Components/globalFrontendComponents/Services";
import Team from "Components/globalFrontendComponents/Team";
import MyPricing from "@/components/globalFrontendComponents/MyPricing";
import ReferralProgram from "@/components/globalFrontendComponents/ReferralProgram";

import MyData from "Data/dashboardone.json";
export default {
  data() {
    return {
      data: MyData.data
    };
  },
  components: {
    bannerVersionOne: Banner,
    PopularCategories: PopularCategories,
    MostVisitedPlaces: MostVisitedPlaces,
    ServicesSection: Services,
    Team: Team,
    Pricing: MyPricing,
    ReferralProgram
  }
};
</script>
