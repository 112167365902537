import {
  GqlAccount,
  GqlAddShopInput,
  GqlCategory,
  GqlCity,
  GqlInsertUserInput,
  GqlShop,
  GqlUser,
  GqlUserStats,
  GqlUserTransaction,
  GqlWithdrawRequestInput,
  GqlSubscription,
  GqlSubcriptionInput,
  GqlAddPromotionInput,
  GqlGpsLocation,
  GqlGallery,
  GqlPicture,
  GqlUpdateShopInput,
  GqlNotification
} from "@/helpers/gql-schema";
import { defineModule } from "direct-vuex";
import { moduleActionContext } from "..";
import { VuexPick } from "@/helpers/vuex-type-helper";
import { dataApiRequest } from "@/helpers/data-api-client";
import { ApiCallResult } from "@/helpers/types";
import { apiRequest } from "@/helpers/api-call";
import { getuserRole } from "@/helpers";
import { CityState, CategoryState, PictureState } from "../common/index";
import { TransactionStatus } from "shared-dependancies";

export type UserType = "admin" | "user";

export type Picture = VuexPick<GqlPicture, "fileName" | "url">;

export type Notification = VuexPick<
  GqlNotification,
  "id" | "content" | "priority" | "notificationDateTime"
>;

export type Gallery = VuexPick<GqlGallery, "id"> & {
  pictures: Picture[];
};

export type Shop = VuexPick<
  GqlShop,
  "id" | "name" | "hasActivePromotion" | "description" | "contact1" | "contact2"
> & {
  city: CityState;
  category: CategoryState;
  firstPicture: PictureState;
  location: GqlGpsLocation;
  gallery: Gallery;
};

export type Referral = VuexPick<GqlUser, "name" | "firstName">;

export type Stats = VuexPick<GqlUserStats, "availableFund" | "referralsCount">;

export type Subscription = VuexPick<
  GqlSubscription,
  "id" | "startDate" | "endDate" | "isActive" | "price" | "payPhone"
>;

export type Transaction = VuexPick<
  GqlUserTransaction,
  | "id"
  | "transactionAmount"
  | "transactionDate"
  | "transactionDescription"
  | "transactionReceiptUrl"
> & {
  transactionStatus: TransactionStatus;
};

export type UserState = VuexPick<
  GqlUser,
  "name" | "firstName" | "shopCount"
> & {
  userType: UserType;
  account:
    | (VuexPick<GqlAccount, "referralCode" | "telephone"> & {
        notifications: Notification[];
      })
    | null;
  stats: Stats | null;
  referrals: Referral[];
  shops: Shop[];
  transactions: Transaction[];
  activeSubscription: Subscription | null;
};

const userNotificationFragment = "{ id notificationDateTime content priority }";
const galleryFragment = "{ id pictures { fileName url } }";
const userSubscriptionFragment = `{ id startDate endDate price isActive payPhone }`;
const userStatsFragment = `{ availableFund referralsCount }`;
const userAccountFragment = `{ referralCode telephone notifications ${userNotificationFragment} }`;
const userReferralsFragment = `{ name firstName }`;
const userInfoFragment = `{ name firstName shopCount account ${userAccountFragment} }`;
const userTransactionFragment = `{ id transactionDate transactionAmount transactionDescription transactionReceiptUrl transactionStatus }`;
const userShopsFragment = `{ 
  id name 
  hasActivePromotion 
  description 
  city { id name } 
  category { id label } 
  firstPicture { url } 
  location { lng lat }
  gallery ${galleryFragment}
  contact1
  contact2  }`;

export interface UserModuleState {
  currentUser: UserState | null;
  shopToUpdateId: string | null;
}

function initialState(): UserModuleState {
  return {
    currentUser: null,
    shopToUpdateId: null
  };
}

const userModule = defineModule({
  namespaced: true,
  state: (): UserModuleState => {
    return initialState();
  },
  getters: {
    userIdentity: state => {
      if (!state.currentUser) return "";
      return state.currentUser?.name + " " + state.currentUser?.firstName;
    }
  },
  actions: {
    async login(
      context,
      payload: { telephone: string; password: string }
    ): Promise<ApiCallResult> {
      const { dispatch, commit, getters, state } = userActionContext(context);
      try {
        const result = await apiRequest("POST", "auth/login", payload, false);

        if (result && result.data) {
          if (result.data.success) {
            localStorage.setItem("accessToken", result.data.data.token);
            localStorage.setItem("userRole", "user");
            return {
              success: true
            };
          }
        }

        return {
          success: false,
          message:
            "Impossible de vous connecter. Veuillez vérifier vos identifiants"
        };
      } catch (error) {
        return {
          success: false,
          message:
            "Une erreur est survenue lors de votre connexion, veuillez réessayer"
        };
      }
    },
    logout(context) {
      const { commit } = userActionContext(context);
      localStorage.removeItem("accessToken");
      localStorage.removeItem("userRole");
      commit.LOGOUT();
      return true;
    },
    async signup(context, payload: GqlInsertUserInput): Promise<ApiCallResult> {
      const { commit } = userActionContext(context);
      try {
        const result = await dataApiRequest(
          `
        mutation($input: InsertUserInput!) {
          insertUser(input: $input)
        }
        `,
          {
            input: payload
          }
        );

        if (result && result.insertUser) {
          let apiCallResult: ApiCallResult;

          switch (result.insertUser) {
            case "Account with same number exists in DB":
              apiCallResult = {
                success: false,
                message:
                  "Un compte est déjà enregistré avec ce numéro de téléphone"
              };
              break;

            default:
              localStorage.setItem("accessToken", result.insertUser);
              commit.SET_USER_ROLE("user");
              apiCallResult = {
                success: true
              };
              break;
          }
          return apiCallResult;
        }
        throw new Error("API error");
      } catch (error) {
        return {
          success: false,
          message:
            "Une erreur est survenue lors de votre inscription, veuillez réessayer"
        };
      }
    },
    async addShop(context, payload: GqlAddShopInput): Promise<ApiCallResult> {
      const { commit } = userActionContext(context);
      try {
        const result = await dataApiRequest(
          `
        mutation($input: AddShopInput!) {
          addShop(input: $input)
        }
        `,
          {
            input: payload
          }
        );

        let apiCallResult: ApiCallResult;
        if (result && result.addShop) {
          apiCallResult = {
            success: true,
            message: "Votre atelier à bien été ajouté. Bienvenu(e) sur kokoko"
          };
        } else {
          apiCallResult = {
            success: false,
            message:
              "une erreur est survenue lors de l'ajout de votre atelier ! Veuillez réessayer"
          };
        }

        return apiCallResult;
      } catch (error) {
        return {
          success: false,
          message:
            "Une erreur est survenue lors de votre inscription, veuillez réessayer"
        };
      }
    },
    async updateShop(
      context,
      payload: GqlUpdateShopInput
    ): Promise<ApiCallResult> {
      const { dispatch, commit } = userActionContext(context);
      try {
        const result = await dataApiRequest(
          `
        mutation($input: UpdateShopInput!) {
          updateShop(input: $input)
        }
        `,
          {
            input: payload
          }
        );

        let apiCallResult: ApiCallResult;
        if (result && result.updateShop) {
          await dispatch.fetchUserDetails();
          apiCallResult = {
            success: true,
            message: "Votre atelier à bien été mis à jour."
          };
        } else {
          apiCallResult = {
            success: false,
            message:
              "une erreur est survenue lors de la mise à jour de votre atelier ! Veuillez réessayer"
          };
        }

        return apiCallResult;
      } catch (error) {
        return {
          success: false,
          message:
            "Une erreur est survenue lors de votre inscription, veuillez réessayer"
        };
      }
    },
    async launchPromotion(
      context,
      payload: GqlAddPromotionInput
    ): Promise<ApiCallResult> {
      const { commit } = userActionContext(context);
      try {
        const result = await dataApiRequest(
          `
        mutation($input: AddPromotionInput!) {
          launchPromotion(input: $input)
        }
        `,
          {
            input: payload
          }
        );

        let apiCallResult: ApiCallResult;
        if (result && result.launchPromotion) {
          apiCallResult = {
            success: true,
            message:
              "Votre promotion à bien été lancée, vous serez mis en avant sur la plateforme"
          };
        } else {
          apiCallResult = {
            success: false,
            message:
              "Une erreur est survenue lors du lancmeent de la promotion ! Veuillez réessayer"
          };
        }

        return apiCallResult;
      } catch (error) {
        return {
          success: false,
          message:
            "Une erreur est survenue lors de votre inscription, veuillez réessayer"
        };
      }
    },
    async getCurrentUserInfo(context): Promise<void> {
      const { commit, rootDispatch } = userActionContext(context);
      try {
        const result = await dataApiRequest(
          `query {
      me ${userInfoFragment}
    }`
        );

        if (result && result.me) {
          commit.SET_USER(result.me);
          commit.SET_USER_ROLE(getuserRole());
        }
      } catch (error) {
        // const typedError = error as ErrorFromBackend;
        localStorage.removeItem("accessToken");
        await rootDispatch.common.updateAccessTokenAvailablity();
      }
    },
    async fetchUserDetails(context): Promise<void> {
      const { commit } = userActionContext(context);

      const result = await dataApiRequest(
        `query {
          me {
            shops ${userShopsFragment}
            referrals ${userReferralsFragment}
            stats ${userStatsFragment}
            transactions ${userTransactionFragment}
            activeSubscription ${userSubscriptionFragment}
          }
        }`
      );

      if (result.me) {
        commit.SET_USER_SHOPS(result.me.shops);
        commit.SET_USER_REFERRALS(result.me.referrals);
        commit.SET_USER_STATS(result.me.stats);
        commit.SET_USER_TRANSACTION(result.me.transactions);
        commit.SET_USER_ACTIVE_SUBSCRIPTION(result.me.activeSubscription);
      }
    },
    async fetchUserStatsAndTransaction(context): Promise<void> {
      const { commit } = userActionContext(context);

      const result = await dataApiRequest(
        `query {
          me {
            stats ${userStatsFragment}
            transactions ${userTransactionFragment}
          }
        }`
      );
      if (result.me) {
        commit.SET_USER_STATS(result.me.stats);
        commit.SET_USER_TRANSACTION(result.me.transactions);
      }
    },
    async sendWithDrawRequest(
      context,
      payload: GqlWithdrawRequestInput
    ): Promise<ApiCallResult> {
      const { state, commit } = userActionContext(context);

      let apiCallResult: ApiCallResult;

      try {
        const result = await dataApiRequest(
          `
        mutation($input: WithdrawRequestInput!) {
          insertWithdrawRequest(input: $input) {
            id
          }
        }
        `,
          {
            input: payload
          }
        );

        if (result && result.insertWithdrawRequest) {
          apiCallResult = {
            success: true,
            message:
              "Votre transaction à bien été enregistré. Le transfert sera effectué sous peu"
          };
        } else {
          apiCallResult = {
            success: false,
            message:
              "une erreur est survenue lors de l'enregistrement de votre transaction ! Veuillez réessayer"
          };
        }

        return apiCallResult;
      } catch (error) {
        apiCallResult = {
          success: false,
          message:
            "une erreur est survenue lors de l'enregistrement de votre transaction ! Veuillez réessayer"
        };
      }

      return apiCallResult;
    },
    async sendSubscriptionUpdateRequest(
      context,
      payload: GqlSubcriptionInput
    ): Promise<ApiCallResult> {
      const { state, commit } = userActionContext(context);

      let apiCallResult: ApiCallResult;

      try {
        const result = await dataApiRequest(
          `
        mutation($input: SubcriptionInput!) {
          updateSubscription(input: $input)
        }
        `,
          {
            input: payload
          }
        );

        if (result && result.updateSubscription) {
          apiCallResult = {
            success: true,
            message: "Votre abonnement à bien été prolongé"
          };
        } else {
          apiCallResult = {
            success: false,
            message:
              "une erreur est survenue lors de la prolongation de votre abonnement ! Veuillez réessayer"
          };
        }

        return apiCallResult;
      } catch (error) {
        apiCallResult = {
          success: false,
          message:
            "une erreur est survenue lors de l'enregistrement de votre transaction ! Veuillez réessayer"
        };
      }

      return apiCallResult;
    },
    async retrieveReferrerInfo(context, referrerCode: string) {
      try {
        const result = await dataApiRequest(
          `query($referrerCode: String!) {
            userByReferrerCode(referrerCode: $referrerCode) {name firstName}
          }`,
          {
            referrerCode
          }
        );

        if (result && result.userByReferrerCode) {
          return result.userByReferrerCode;
        }

        return undefined;
      } catch (error) {}
    }
    // updateUserLoginStatus(context): void {
    //   const { commit } = userActionContext(context);
    //   commit.SET_USER_LOG_STATUS(getToken() !== undefined);
    // }
  },
  mutations: {
    SET_USER_ROLE(state, type: UserType | undefined) {
      if (!state.currentUser || !type) return;
      state.currentUser.userType = type;
      localStorage.setItem("userRole", "admin");
    },
    SET_USER(state, user: UserState) {
      if (state.currentUser == null) {
        state.currentUser = {
          name: "",
          firstName: "",
          userType: "user",
          account: null,
          stats: null,
          referrals: [],
          shops: [],
          transactions: [],
          activeSubscription: null,
          shopCount: 0
        };
      }
      state.currentUser!.account = user.account;
      state.currentUser!.name = user.name;
      state.currentUser!.firstName = user.firstName;
      state.currentUser!.shops = [];
      state.currentUser!.referrals = [];
      state.currentUser!.stats = { availableFund: 0, referralsCount: 0 };
      state.currentUser!.shopCount = user.shopCount;
      // state.loggedUser = true;
    },
    SET_USER_SHOPS(state, shops: Shop[]) {
      if (!state.currentUser) return;
      state.currentUser.shops = shops;
    },
    SET_USER_REFERRALS(state, referrals: Referral[]) {
      if (!state.currentUser) return;
      state.currentUser.referrals = referrals;
    },
    SET_USER_STATS(state, stats: Stats) {
      if (!state.currentUser) return;
      state.currentUser.stats = stats;
    },
    SET_USER_TRANSACTION(state, transactions: Transaction[]) {
      if (!state.currentUser) return;
      state.currentUser.transactions = transactions;
    },
    SET_USER_ACTIVE_SUBSCRIPTION(state, subscription: Subscription) {
      if (!state.currentUser) return;
      state.currentUser.activeSubscription = subscription;
    },
    SET_USER_SHOP_TO_UPDATE_ID(state, shopId: string) {
      if (!state.currentUser) return;
      state.shopToUpdateId = shopId;
    },
    LOGOUT(state) {
      state.currentUser = null;
    }
  }
});

export default userModule;
const userActionContext = (context: any) =>
  moduleActionContext(context, userModule);
