










































import {
  defineComponent,
  PropType,
  reactive,
  computed,
  ref,
  watch,
  onMounted
} from "@vue/composition-api";
import { GalleryState } from "@/store/common";
import { Gallery, Picture } from "@/store/user";
import Dropzone from "vue2-dropzone";
import {
  getDropZoneOptions,
  MAX_SHOP_GALLERY_FILES_COUNT,
  getEnvVariable
} from "@/helpers";
import store from "@/store";
import { apiRequest } from "@/helpers/api-call";

export default defineComponent({
  name: "ShopGalleryUpdater",
  props: {
    gallery: {
      required: true,
      type: Object as PropType<Gallery>
    },
    launchPictureUpload: {
      required: true,
      type: Boolean
    }
  },
  components: {
    Dropzone
  },
  setup(props, context) {
    const vueDropzone = ref<any>();

    const dropZoneOptions = {
      ...getDropZoneOptions(),
      method: "PATCH",
      url: `${getEnvVariable().backendUrl}/gallery/${props.gallery.id}`
    };

    const state = reactive({
      filesToDelete: [] as string[],
      shopPicturesToShow: computed((): Picture[] => {
        return props.gallery.pictures.filter(
          picture => !state.filesToDelete.includes(picture.fileName)
        );
      }),
      canShowDropzone: computed(
        (): boolean =>
          state.shopPicturesToShow.length < MAX_SHOP_GALLERY_FILES_COUNT
      ),
      dropZoneCurrentFileCount: 0,
      remainingFileToAddCount: computed(
        (): number =>
          MAX_SHOP_GALLERY_FILES_COUNT - state.dropZoneCurrentFileCount
      ),
      canAddFileToDropZone: computed((): boolean =>
        state.dropZoneCurrentFileCount === 0
          ? true
          : state.dropZoneCurrentFileCount < state.remainingFileToAddCount
      ),
      isDropzoneDisabled: computed(
        (): boolean => state.canShowDropzone && !state.canAddFileToDropZone
      ),
      uploadComplete: false,
      uploadFailed: false
    });

    onMounted(() => {});

    function deleteFile(fileName: string) {
      state.filesToDelete.push(fileName);
    }

    function onDropZoneChange() {
      const acceptedFiles = vueDropzone.value?.getAcceptedFiles();
      state.dropZoneCurrentFileCount = Math.max(0, acceptedFiles.length);
    }

    function onMultiUploadComplete(files: any, response: any) {
      state.uploadComplete = true;
    }

    function onMultiUploadError(files: any, message: string, xhr: any) {
      state.uploadFailed = true;
      console.error(message);
    }

    function onSendingEvent(file: any, xhr: any, formData: any) {
      formData.append("filesToDelete", state.filesToDelete);
    }

    watch(
      () => props.launchPictureUpload,
      async value => {
        if (value) {
          if (!vueDropzone.value) {
            context.emit("uploadResultAvailable", true);
          }
          const acceptedFilesCount = (vueDropzone.value as any).getAcceptedFiles()
            .length;

          if (acceptedFilesCount < 1 && props.gallery.pictures.length < 1) {
            context.root.$notify({
              group: "updateProcessNotifs",
              type: "warning",
              title: "Oupss",
              text: "Veuillez fournir au moins une photo"
            });
            context.emit("uploadResultAvailable", false);
            return;
          }

          if (acceptedFilesCount === 0) {
            context.emit(
              "uploadResultAvailable",
              await sendFilesToDeleteOnly()
            );
          }

          vueDropzone.value?.processQueue();
          await uploadPictures();
          context.emit(
            "uploadResultAvailable",
            state.uploadComplete || state.uploadFailed
          );
        }
      }
    );

    async function uploadPictures() {
      return new Promise((resolve, reject) => {
        const interval = setInterval(() => {
          if (state.uploadComplete) {
            resolve();
            clearInterval(interval);
            return;
          }
          if (state.uploadFailed) {
            reject();
            clearInterval(interval);
          }
        }, 1000);
      });
    }

    async function sendFilesToDeleteOnly() {
      const data = new FormData();
      data.append("filesToDelete", state.filesToDelete.toString());
      const result = await apiRequest(
        "PATCH",
        `gallery/${props.gallery.id}`,
        data,
        true
      );
      if (result?.status === 200) state.uploadComplete = true;
      else state.uploadFailed = true;
      return result?.status === 200;
    }

    return {
      props,
      state,
      dropZoneOptions,
      vueDropzone,
      deleteFile,
      onDropZoneChange,
      onMultiUploadComplete,
      onMultiUploadError,
      onSendingEvent
    };
  }
});
