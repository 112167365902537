import FrontendPanel from "../layouts/frontendPanel/FrontendPanel.vue";

// dashboard components
import DashboardOne from "../views/dashboard/DashboardOne.vue";
import AddListing from "../views/pages/AddListing.vue";
import UpdateListing from "../views/pages/UpdateListing.vue";
import LaunchPromotion from "../views/pages/LaunchPromotion.vue";
import UserProfile from "../views/pages/UserProfile.vue";
import MapsWithShop from "../views/listing/MapsWithShop.vue";
import ShopDetails from "../views/listing/ShopDetails.vue";
import { Route } from "vue-router";

const clientRoutes = {
  path: "/",
  component: FrontendPanel,
  redirect: "/accueil",
  children: [
    {
      path: "/accueil",
      component: DashboardOne,
      meta: {
        title: "Home"
      }
    },
    {
      path: "/artisans-a-proximite",
      // path: "/app?categoryFilter=:categoryFilter&cityFilterId=:cityFilterId", // NOTES: The path can also be like this, allowing to pass filter params as Props to the component
      component: MapsWithShop,
      props: (route: Route) => ({ ...route.query }),
      meta: {
        title: "La carte"
      }
    },
    {
      path: "/pages/ajouter-service",
      component: AddListing,
      name: "AddListing",
      meta: {
        title: "Ajouter votre service"
      }
    },
    {
      path: "/pages/actualiser-service/:shopId",
      component: UpdateListing,
      name: "UpdateListing",
      meta: {
        title: "Mettez à jour les infos de votre service"
      },
      props: (route: any) => ({ shopId: route.params.shopId })
    },
    {
      path: "/pages/lancer-promotion",
      component: LaunchPromotion,
      name: "LaunchPromotion",
      meta: {
        title: "Lancer une promotion"
      }
    },
    {
      path: "/mon-profil",
      name: "UserProfile",
      component: UserProfile,
      meta: {
        title: "User Profile"
      }
    },
    {
      path: "/listing/detail/version1",
      component: ShopDetails,
      meta: {
        title: "Listing Detail One"
      }
    },
    {
      path: "/shop/details/:shopId",
      component: ShopDetails,
      props: (route: any) => ({
        shopId: route.params["shopId"]
      }),
      meta: {
        title: "Listing Detail One"
      }
    }
  ]
};

export default clientRoutes;
