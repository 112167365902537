













































import store from "@/store";
import { TeamMemberState } from "@/store/common";
import {
  computed,
  defineComponent,
  onMounted,
  reactive
} from "@vue/composition-api";

export default defineComponent({
  name: "PlatformTeam",
  props: {
    title: {
      required: true,
      type: String
    },
    desc: {
      required: true,
      type: String
    }
  },
  setup(props, context) {
    const state = reactive({
      members: computed((): TeamMemberState[] => store.state.common.teamMembers)
    });

    onMounted(async () => {
      await store.dispatch.common.fetchTeamMembers();
    });

    return {
      props,
      state
    };
  }
});
// {
//   props: ["title", "desc", "data"]
// };
