import {
  GqlCategory,
  GqlCity,
  GqlGallery,
  GqlGpsLocation,
  GqlInsertUserInput,
  GqlPicture,
  GqlPromotion,
  GqlShop,
  GqlTeamMember,
  GqlUser
} from "@/helpers/gql-schema";
import { defineModule } from "direct-vuex";
import { moduleActionContext } from "..";
import { VuexPick } from "@/helpers/vuex-type-helper";
import { dataApiRequest } from "@/helpers/data-api-client";
import { ApiCallResult, ApprovedTransaction } from "@/helpers/types";
import { apiRequest } from "@/helpers/api-call";
import { getCurrentPosition, getToken, haversineDistance } from "@/helpers";
import { PlatformSetup } from "shared-dependancies";

const pictureFragment = "{ url }";

const categoriesFragment = `{ id label picture ${pictureFragment} normalIcon ${pictureFragment} featuredIcon ${pictureFragment} }`;
const teamsFragment = `{ id identity title }`;
const citiesFragment = `{ id name department }`;
const promotionFragment =
  "{ id isActive startDate endDate price payPhone promotionText gallery {pictures {url}}}";

const displayableShopFragment = `{ id name hasActivePromotion location { lat lng } category ${categoriesFragment} city ${citiesFragment} firstPicture { url } }`;
const fullShopInfoFragment = `{ 
  id name 
  location { lat lng } 
  category ${categoriesFragment} 
  city ${citiesFragment} 
  contact1
  contact2
  description
  gallery {
    pictures {url}
  }
  manager {
    firstName
    name
  }
  currentlyActivePromotion ${promotionFragment}
}`;

export type CategoryState = VuexPick<
  GqlCategory,
  "id" | "label" | "picture" | "featuredIcon" | "normalIcon"
>;
export type CityState = VuexPick<GqlCity, "id" | "name" | "department">;
export type PictureState = VuexPick<GqlPicture, "url">;
export type LocationState = VuexPick<GqlGpsLocation, "lng" | "lat">;
export type GalleryState = VuexPick<GqlGallery, "id"> & {
  pictures: PictureState[];
};
export type ManagerState = VuexPick<GqlUser, "firstName" | "name">;
export type PromotionState = VuexPick<
  GqlPromotion,
  | "id"
  | "isActive"
  | "startDate"
  | "endDate"
  | "price"
  | "payPhone"
  | "promotionText"
> & {
  gallery: GalleryState;
};

export type DisplayableShop = VuexPick<
  GqlShop,
  "id" | "name" | "hasActivePromotion" | "description" | "contact1" | "contact2"
> & {
  city: CityState;
  category: CategoryState;
  firstPicture: PictureState;
  location: LocationState;
  distanceToUser: number;
};

export type FullShopInfo = DisplayableShop & {
  gallery: GalleryState;
  manager: ManagerState;
  currentlyActivePromotion: PromotionState;
};

export type TeamMemberState = VuexPick<
  GqlTeamMember,
  "id" | "identity" | "title"
>;

export interface CommonModuleState {
  categories: CategoryState[];
  teamMembers: TeamMemberState[];
  cities: CityState[];
  platformSetup: PlatformSetup;
  routeWaitingForLoggedUser: string | null;
  shops: DisplayableShop[];
  featuredShops: DisplayableShop[];
  accessTokenAvailable: boolean;
}

function initialState(): CommonModuleState {
  return {
    categories: [],
    teamMembers: [],
    cities: [],
    platformSetup: {
      setupId: null,
      monthlyFee: null,
      referralPercent: null,
      minimumAmountToWithdral: null,
      frontendUrl: null,
      promotionDailyPrice: null
    },
    routeWaitingForLoggedUser: null,
    shops: [],
    featuredShops: [],
    accessTokenAvailable: false
  };
}

const commonModule = defineModule({
  namespaced: true,
  state: (): CommonModuleState => {
    return initialState();
  },
  getters: {},
  actions: {
    async fetchCategories(context): Promise<void> {
      const { state, commit } = commonActionContext(context);
      if (state.categories.length !== 0) return;
      const result = await dataApiRequest(
        `query {
          categories ${categoriesFragment}
        }`
      );

      if (result && result.categories) {
        commit.SET_CATEGORIES(result.categories);
      }
    },
    async fetchTeamMembers(context): Promise<void> {
      const { state, commit } = commonActionContext(context);
      if (state.teamMembers.length !== 0) return;
      const result = await dataApiRequest(
        `query {
          platformTeams ${teamsFragment}
        }`
      );

      if (result && result.platformTeams) {
        commit.SET_TEAMS(result.platformTeams);
      }
    },
    async fetchCities(context): Promise<void> {
      const { state, commit } = commonActionContext(context);
      if (state.cities.length !== 0) return;
      const result = await dataApiRequest(
        `query {
          cities ${citiesFragment}
        }`
      );

      if (result && result.cities) {
        commit.SET_CITIES(result.cities);
      }
    },
    async loadPlatformSetup(context): Promise<void> {
      const { state, commit } = commonActionContext(context);

      const result = await apiRequest("GET", "config");

      if (result?.data) {
        commit.SET_PLATFORM_SETUP(result.data);
      }
    },
    async verifyPayment(
      _context,
      paymentId: number
    ): Promise<ApprovedTransaction | undefined> {
      const verificationResult = await apiRequest(
        "GET",
        `payment/verify/${paymentId}`
      );

      console.log("verificationResult:", verificationResult);

      return verificationResult?.data;
    },
    async fetchNearestShops(context): Promise<void> {
      const userLocation = await getCurrentPosition();

      const { commit } = commonActionContext(context);

      const result = await dataApiRequest(
        `query($userLocation: GpsLocationInput!) {
          nearestShops(userLocation: $userLocation) ${displayableShopFragment}
        }`,
        {
          userLocation
        }
      );

      if (result && result.nearestShops) {
        (result.nearestShops as DisplayableShop[]).forEach(shop => {
          Object.assign(shop, {
            distanceToUser: parseFloat(
              haversineDistance(shop.location, userLocation).toFixed(2)
            )
          });
        });

        (result.nearestShops as DisplayableShop[]).sort(
          (a, b) => a.distanceToUser - b.distanceToUser
        );
        commit.SET_NEAREST_SHOPS(result.nearestShops);
      }
    },
    async fetchFeaturedShops(context): Promise<void> {
      // const userLocation = await getCurrentPosition();

      const { commit } = commonActionContext(context);

      const result = await dataApiRequest(
        `query {
          featuredShops ${displayableShopFragment}
        }`
      );

      if (result && result.featuredShops) {
        commit.SET_FEATURED_SHOPS(result.featuredShops);
      }
    },
    async fetchShopDetails(
      _,
      shopId: string
    ): Promise<FullShopInfo | undefined> {
      const result = await dataApiRequest(
        `query($shopId: ID!) {
          shopDetails(shopId: $shopId) ${fullShopInfoFragment}
        }`,
        {
          shopId
        }
      );

      if (result && result.shopDetails) return result.shopDetails;

      return undefined;
    },
    updateAccessTokenAvailablity(context): void {
      const { commit } = commonActionContext(context);
      commit.SET_ACCESS_TOKEN_AVAILABILITY(getToken() !== undefined);
    }
  },
  mutations: {
    SET_CATEGORIES(state, categories: CategoryState[]) {
      state.categories = categories;
    },
    SET_TEAMS(state, teamMembers: TeamMemberState[]) {
      state.teamMembers = teamMembers;
    },
    SET_CITIES(state, cities: CityState[]) {
      state.cities = cities;
    },
    SET_PLATFORM_SETUP(state, setup: PlatformSetup) {
      Object.assign(state.platformSetup, setup);
      state.platformSetup = setup;
    },
    SET_WAITING_FOR_LOGIN_ROUTE(state, route: string) {
      state.routeWaitingForLoggedUser = route;
    },
    SET_NEAREST_SHOPS(state, shops: DisplayableShop[]) {
      state.shops = shops;
    },
    SET_FEATURED_SHOPS(state, shops: DisplayableShop[]) {
      state.featuredShops = shops;
    },
    RESET_WAITING_FOR_LOGIN_ROUTE(state) {
      state.routeWaitingForLoggedUser = null;
    },
    SET_ACCESS_TOKEN_AVAILABILITY(state, status: boolean) {
      state.accessTokenAvailable = status;
    }
  }
});

export default commonModule;
const commonActionContext = (context: any) =>
  moduleActionContext(context, commonModule);
