




































































































































































































































import TitleBar from "@/components/globalFrontendComponents/TitleBar.vue";
import Dropzone from "vue2-dropzone";
import VuePhoneNumberInput from "vue-phone-number-input";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch
} from "@vue/composition-api";
import { getPhoneInputOptions } from "@/helpers/phone-input";
import router from "@/router";
import { CategoryState, CityState, GalleryState } from "@/store/common";
import store from "@/store";
import { getCurrentPosition } from "@/helpers";
import {
  GqlGpsLocation,
  GqlGpsLocationInput,
  GqlUpdateShopInput
} from "@/helpers/gql-schema";
import SubscriptionInput from "@/components/Forms/SubscriptionInput.vue";
import ShopGalleryUpdater from "@/components/UserProfile/ShopGalleryUpdater.vue";
import LoadingScreen from "@/components/LoadingScreen.vue";
import { Shop, Gallery } from "@/store/user";

export default defineComponent({
  name: "UpdateListing",
  components: {
    TitleBar: TitleBar,
    Dropzone,
    VuePhoneNumberInput,
    SubscriptionInput,
    LoadingScreen,
    ShopGalleryUpdater
  },
  props: {
    shopId: {
      required: true,
      type: String
    }
  },
  setup(props, context) {
    const vueDropzone = ref(null);
    const phone1input = ref(null);
    const phone2input = ref(null);

    const todayDate = new Date();

    const state = reactive({
      dataLoadingComplete: false,
      contact: "",
      categories: computed(
        (): CategoryState[] => store.state.common.categories
      ),
      shopToUpdateId: computed((): string | null => props.shopId),
      cities: computed((): CityState[] => store.state.common.cities),
      uploadComplete: false,
      uploadFailed: false,
      uploadedPictureGalleryId: null as number | null,
      listingInfo: {
        name: "",
        categoryId: null as number | null,
        cityId: null as number | null,
        description: "",
        phone1: "",
        phone2: "",
        gpsLocation: null as GqlGpsLocation | null,
        isLocalisationReset: false as boolean
      },
      validator: {
        isValidName: computed((): boolean => state.listingInfo.name.length > 3),
        isValidCategory: computed(
          (): boolean => state.listingInfo.categoryId !== null
        ),
        isValidCity: computed((): boolean => state.listingInfo.cityId !== null),
        isValidGpsLocation: computed(
          (): boolean => state.listingInfo.gpsLocation !== null
        ),
        isValidDescription: computed(
          (): boolean => state.listingInfo.description.length > 10
        ),
        isPhone1NumberValid: false,
        isPhone2NumberValid: true,
        canSubmit: computed((): boolean => {
          return (
            state.validator.isValidName &&
            state.validator.isValidCategory &&
            state.validator.isValidCity &&
            state.validator.isValidDescription &&
            state.validator.isPhone1NumberValid &&
            state.validator.isPhone2NumberValid &&
            state.validator.isValidGpsLocation
          );
        })
      },
      isLoading: false,
      currentProcessMessage: "Mettre à jour",
      shopGallery: computed(
        (): Gallery | undefined =>
          store.state.users.currentUser?.shops.find(
            shop => shop.id === state.shopToUpdateId
          )?.gallery
      ),
      launchPictureUpload: false
    });

    const phoneInputOptions = getPhoneInputOptions();

    onMounted(async () => {
      if (!store.state.users.currentUser) {
        store.commit.common.SET_WAITING_FOR_LOGIN_ROUTE("UpdateListing");
        void router.push("/session/login");
        return;
      }

      if (store.state.users.currentUser?.shops.length === 0) {
        await store.dispatch.users.fetchUserDetails();
      }

      if (!props.shopId) {
        store.commit.common.SET_WAITING_FOR_LOGIN_ROUTE("UpdateListing");
        void router.push("/mon-profil");
        return;
      }

      await store.dispatch.common.fetchCategories();
      await store.dispatch.common.fetchCities();
      await store.dispatch.common.loadPlatformSetup();

      syncUiToShopDetails();

      state.dataLoadingComplete = true;
    });

    async function launchUpdateProcess() {
      if (state.isLoading) return;
      if (!state.listingInfo.gpsLocation) {
        context.root.$notify({
          group: "updateProcessNotifs",
          type: "warning",
          title: "Oupss",
          text: "Veuillez fournir votre localisation gps"
        });
        return;
      }
      launchUploadProcess();
      state.isLoading = true;
    }

    async function updateShop() {
      state.currentProcessMessage = "Mise à jour en cours";

      const payload: GqlUpdateShopInput = {
        shopId: state.shopToUpdateId!,
        name: state.listingInfo.name,
        cityId: state.listingInfo.cityId!.toString(),
        categoryId: state.listingInfo.categoryId!.toString(),
        contact1: state.listingInfo.phone1,
        contact2: state.listingInfo.phone2,
        location: state.listingInfo.gpsLocation as GqlGpsLocationInput,
        description: state.listingInfo.description
      };

      const shopUpdateResult = await store.dispatch.users.updateShop(payload);

      if (!shopUpdateResult.success) {
        context.root.$notify({
          group: "updateProcessNotifs",
          type: "error",
          title: "Une erreur est survenue lors de la mise à jour",
          text: shopUpdateResult.message
        });
        state.isLoading = false;
        state.currentProcessMessage = "Mettre à jour";
      } else {
        router.replace("/mon-profil");
      }
    }

    function onPhone1InputUpdate(eventParams: any) {
      state.validator.isPhone1NumberValid = eventParams.isValid;
    }

    function onPhone2InputUpdate(eventParams: any) {
      if (!eventParams.phoneNumber) state.validator.isPhone2NumberValid = true;
      else state.validator.isPhone2NumberValid = eventParams.isValid;
    }

    function syncUiToShopDetails() {
      const shopIndex = store.state.users.currentUser?.shops.findIndex(
        shop => shop.id === state.shopToUpdateId
      );
      if (shopIndex === -1 || shopIndex === undefined) return;
      const theShop = store.state.users.currentUser?.shops[
        shopIndex
      ] as NonNullable<Shop>;
      state.listingInfo.name = theShop.name;
      state.listingInfo.description = theShop.description ?? "";
      state.listingInfo.categoryId = parseInt(theShop.category.id);
      state.listingInfo.cityId = parseInt(theShop.city.id);
      state.listingInfo.phone1 = theShop.contact1;
      state.listingInfo.phone2 = theShop.contact2 ?? "";
      state.listingInfo.gpsLocation = theShop.location;

      var file = { size: 123, name: "Icon", type: "image/png" };
      if (vueDropzone.value)
        (vueDropzone.value as any).manuallyAddFile(
          file,
          theShop.gallery.pictures[0].url
        );
    }

    async function locateMe() {
      try {
        const shopLocation = await getCurrentPosition();
        state.listingInfo.gpsLocation = shopLocation;
        state.listingInfo.isLocalisationReset = true;
      } catch (error) {
        context.root.$notify({
          group: "updateProcessNotifs",
          type: "warning",
          title: "Oupss",
          text:
            "Impossible de déterminer votre position gps. Veuillez vérifier que votre GPS est activé"
        });
      }
    }

    function launchUploadProcess() {
      state.launchPictureUpload = true;
      state.currentProcessMessage = "Téléchargement de vos photos";
    }

    function onUploadResultAvailable(result: boolean) {
      if (result) {
        updateShop();
      } else {
        state.launchPictureUpload = false;
        state.isLoading = false;
        state.currentProcessMessage = "Mettre à jour";
        context.root.$notify({
          group: "updateProcessNotifs",
          type: "warning",
          title: "Oupss",
          text:
            "Les images n'ont pas pu être transférées sur le serveur. Veuillez réessayer"
        });
      }
    }

    return {
      state,
      props,
      phoneInputOptions,
      locateMe,
      vueDropzone,
      updateShop,
      onPhone1InputUpdate,
      onPhone2InputUpdate,
      phone1input,
      phone2input,
      onUploadResultAvailable,
      launchUpdateProcess
    };
  }
});
