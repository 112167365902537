







































































































































































































































































type TabName = "referrals" | "transactions" | "security";

import TitleBar from "@/components/globalFrontendComponents/TitleBar.vue";
import Referrals from "@/components/UserProfile/Referrals.vue";
import Transactions from "@/components/UserProfile/Transactions.vue";
import Notifications from "@/components/UserProfile/Notifications.vue";
import Security from "@/components/UserProfile/Security.vue";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import Avatar from "vue-avatar";
import store from "@/store";
import { Referral, Shop } from "@/store/user/index";
import router from "@/router";

export default defineComponent({
  name: "UserProfile",
  components: {
    TitleBar,
    Avatar,
    Referrals,
    Transactions,
    Security,
    UserNotifications: Notifications,
  },
  setup(_props, context) {
    const state = reactive({
      currentTab: "referrals" as TabName,
      userIdentity: computed((): string => store.getters.users.userIdentity),
      userReferrerCode: computed(
        (): string | undefined =>
          store.state.users.currentUser?.account?.referralCode
      ),
      userShops: computed(
        (): Shop[] => store.state.users.currentUser?.shops ?? []
      ),
      showPromotionModal: false,
      userHasNotifications: computed(
        (): boolean =>
          (store.state.users.currentUser?.account?.notifications ?? []).length >
          0
      ),
    });

    function setCurrentTab(tab: TabName) {
      state.currentTab = tab;
    }

    onMounted(async () => {
      await ensureUserInfoAreRetrieved();

      await store.dispatch.users.fetchUserDetails();
    });

    async function ensureUserInfoAreRetrieved() {
      if (!store.state.common.accessTokenAvailable) {
        store.commit.common.SET_WAITING_FOR_LOGIN_ROUTE("UserProfile");
        void router.push("/session/login");
        return;
      } else if (!store.state.users.currentUser) {
        await store.dispatch.users.getCurrentUserInfo();
      }

      if (!store.state.users.currentUser) {
        store.commit.common.SET_WAITING_FOR_LOGIN_ROUTE("UserProfile");
        void router.push("/session/login");
      }
    }

    function onInviteLinkCopied() {
      context.root.$notify({
        group: "userProfileNotifications",
        type: "success",
        title: "Terminé",
        text: "Votre lien de parrainage à été copié",
      });
    }

    function showPromotionModal() {
      router.push("/pages/lancer-promotion");
    }

    function updateShop(shopId: string) {
      store.commit.users.SET_USER_SHOP_TO_UPDATE_ID(shopId);
      void router.push(`/pages/actualiser-service/${shopId}`);
    }

    return {
      state,
      setCurrentTab,
      onInviteLinkCopied,
      showPromotionModal,
      updateShop,
    };
  },
});
