interface PhoneInputOptions {
  defaultCountryCode: string;
  onlyCountries: string[];
  translations: {
    countrySelectorLabel: string;
    countrySelectorError: String;
    phoneNumberLabel: string;
    example: string;
  };
}
export function getPhoneInputOptions(): PhoneInputOptions {
  return {
    defaultCountryCode: "BJ",
    onlyCountries: ["BJ"],
    translations: {
      countrySelectorLabel: "Code pays",
      countrySelectorError: "Choisir un pays",
      phoneNumberLabel: "Numéro de téléphone",
      example: "Exemple :"
    }
  };
}
