import Vue from "vue";
import VueCompositionApi from "@vue/composition-api";
import * as VueGoogleMaps from "vue2-google-maps";
import App from "./App.vue";
import Vodal from "vodal";
import Vuex from "vuex";

// import "bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
// router
import router from "./router/index";

// include script file
import "./lib/CustomScript";

// include all css files
import "./lib/CustomCss";

import "vue-phone-number-input/dist/vue-phone-number-input.css";

import "vue2-animate/dist/vue2-animate.min.css";

import "vodal/common.css";
import "vodal/door.css";
import "sweetalert2/src/sweetalert2.scss";
import "vue-wysiwyg/dist/vueWysiwyg.css";

import Notifications from "vue-notification";

import wysiwyg from "vue-wysiwyg";

import store from "./store";

export const $ = (require("jquery")(window as any).JQuery = require("jquery"));

Vue.use(VueCompositionApi);

Vue.component(Vodal.name, Vodal);

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDOwO_bZ5Ge3CR7Bsu7jiPMxViN1JCTQsg" // Add your here your google map api key
  }
});

Vue.use(Vuex);

Vue.use(Notifications);

Vue.use(wysiwyg, {
  hideModules: { image: true, link: true, table: true },
  maxHeight: "100px"
});

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App),
  store: store.original,
  components: { App }
}).$mount("#app");
