


















import {
  defineComponent,
  reactive,
  computed,
  onMounted,
  ref
} from "@vue/composition-api";
import { CategoryState } from "@/store/common";
import store from "@/store";

export default defineComponent({
  name: "CategoryFilter",
  components: {},
  props: {
    categoryFilter: {
      type: String,
      required: false
    }
  },
  setup(props, context) {
    const myFilter = ref(null);

    const state = reactive({
      shopCategory: computed(
        (): CategoryState[] => store.state.common.categories
      ),
      categoryFilters: [] as string[]
    });

    let isDown = false;
    let startX: number;
    let scrollLeft: number;

    onMounted(() => {
      setUpHorizontalScroller();

      if (props.categoryFilter)
        state.categoryFilters.push(props.categoryFilter);
    });

    function setUpHorizontalScroller() {
      const slider = document.querySelector("#categoryFilter");
      slider?.addEventListener("mousedown", (e: Event) => {
        isDown = true;
        slider.classList.add("active");
        startX = (e as any).pageX - (slider as any).offsetLeft;
        scrollLeft = slider.scrollLeft;
      });

      slider?.addEventListener("mouseleave", () => {
        isDown = false;
        slider.classList.remove("active");
      });

      slider?.addEventListener("mouseup", () => {
        isDown = false;
        slider.classList.remove("active");
      });

      slider?.addEventListener("mousemove", e => {
        if (!isDown) return;
        e.preventDefault();
        const x = (e as any).pageX - (slider as any).offsetLeft;
        const walk = (x - startX) * 1; //scroll-fast
        slider.scrollLeft = scrollLeft - walk;
      });
    }

    function isSelectedCategory(categoryId: string) {
      return state.categoryFilters.includes(categoryId);
    }

    function toggleCategory(categoryId: string) {
      console.log("categoryId:", categoryId);
      context.emit("categoryFilter", categoryId);
      if (isSelectedCategory(categoryId)) {
        state.categoryFilters.splice(
          state.categoryFilters.findIndex(el => el === categoryId),
          1
        );
        return;
      }
      state.categoryFilters.push(categoryId);
    }

    return {
      state,
      myFilter,
      isSelectedCategory,
      toggleCategory
    };
  }
});
