










import {
  defineComponent,
  onMounted,
  ref,
  onBeforeMount
} from "@vue/composition-api";
import store from "./store";
import LoadingScreen from "@/components/LoadingScreen.vue";
export default defineComponent({
  name: "App",
  components: {
    LoadingScreen
  },
  setup(_props, context) {
    const isLoading = ref(false);

    onBeforeMount(async () => {
      store.dispatch.common.loadPlatformSetup();

      if (
        store.state.common.accessTokenAvailable &&
        !store.state.users.currentUser
      ) {
        isLoading.value = true;
        await store.dispatch.users.getCurrentUserInfo();
        isLoading.value = false;
      }
    });

    return {
      isLoading
    };
  }
});
