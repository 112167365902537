




















































































































































































































































































import TitleBar from "@/components/globalFrontendComponents/TitleBar.vue";
import Dropzone from "vue2-dropzone";
import VuePhoneNumberInput from "vue-phone-number-input";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
} from "@vue/composition-api";
import { getPhoneInputOptions } from "@/helpers/phone-input";
import router from "@/router";
import { CategoryState, CityState } from "@/store/common";
import store from "@/store";
import { getCurrentPosition, getDropZoneOptions } from "@/helpers";
import {
  GqlAddShopInput,
  GqlGpsLocation,
  GqlGpsLocationInput,
} from "@/helpers/gql-schema";
import { addDays, format } from "date-fns";
import SubscriptionInput from "@/components/Forms/SubscriptionInput.vue";
import LoadingScreen from "@/components/LoadingScreen.vue";
import { ApprovedTransaction, SubscriptionPriceDetails } from "@/helpers/types";
import Swal from "sweetalert2";

const fedaPayPublicKey = process.env.VUE_APP_FEDAPAY_PUBLIC_KEY;

export default defineComponent({
  name: "AddListing",
  components: {
    TitleBar: TitleBar,
    Dropzone,
    VuePhoneNumberInput,
    SubscriptionInput,
    LoadingScreen,
  },
  setup(_props, context) {
    const vueDropzone = ref(null);
    const phone1input = ref(null);
    const phone2input = ref(null);

    const todayDate = new Date();

    const state = reactive({
      dataLoadingComplete: false,
      contact: "",
      categories: computed(
        (): CategoryState[] => store.state.common.categories
      ),
      cities: computed((): CityState[] => store.state.common.cities),
      uploadComplete: false,
      uploadFailed: false,
      uploadedPictureGalleryId: null as number | null,
      listingInfo: {
        name: "",
        categoryId: null as number | null,
        cityId: null as number | null,
        description: "",
        phone1: store.state.users.currentUser?.account?.telephone || "",
        phone2: "",
        gpsLocation: null as GqlGpsLocation | null,
      },
      subscriptionPriceDetails: {
        monthCount: 1,
        price: computed(
          (): number => store.state.common.platformSetup?.monthlyFee ?? 0
        ),
      } as SubscriptionPriceDetails,
      subscriptionDetails: {
        startDate: format(todayDate, "dd/MM/yyyy"),
        endDate: computed((): string =>
          format(
            addDays(todayDate, state.subscriptionPriceDetails.monthCount * 30),
            "dd/MM/yyyy"
          )
        ),
        transactionId: undefined as any,
      },
      validator: {
        isValidName: computed((): boolean => state.listingInfo.name.length > 3),
        isValidCategory: computed(
          (): boolean => state.listingInfo.categoryId !== null
        ),
        isValidCity: computed((): boolean => state.listingInfo.cityId !== null),
        isValidGpsLocation: computed(
          (): boolean => state.listingInfo.gpsLocation !== null
        ),
        isValidDescription: computed(
          (): boolean => state.listingInfo.description.length > 10
        ),
        isPhone1NumberValid: false,
        isPhone2NumberValid: true,
        canSubmit: computed((): boolean => {
          return (
            state.validator.isValidName &&
            state.validator.isValidCategory &&
            state.validator.isValidCity &&
            state.validator.isValidDescription &&
            state.validator.isPhone1NumberValid &&
            state.validator.isValidGpsLocation
          );
        }),
      },
      isLoading: false,
      currentProcessMessage: "AJOUTER",
    });

    const phoneInputOptions = getPhoneInputOptions();
    const dropzoneOptions = getDropZoneOptions();

    onMounted(async () => {
      if (!store.state.users.currentUser) {
        store.commit.common.SET_WAITING_FOR_LOGIN_ROUTE("AddListing");
        void router.push("/session/login");
        return;
      }

      // if (store.state.users.currentUser.shops.length !== 0) {
      //   void router.push("/mon-profil");
      //   return;
      // }

      await store.dispatch.common.fetchCategories();
      await store.dispatch.common.fetchCities();
      await store.dispatch.common.loadPlatformSetup();

      state.dataLoadingComplete = true;
    });

    async function locateMe() {
      try {
        const shopLocation = await getCurrentPosition();
        state.listingInfo.gpsLocation = shopLocation;
      } catch (error) {
        Swal.fire({
          title: "Oupss",
          text: "Impossible de déterminer votre position gps. Veuillez vérifier que votre GPS est activé",
          icon: "warning",
          confirmButtonText: "OK",
        });
        // context.root.$notify({
        //   group: "addProcessNotifs",
        //   type: "warning",
        //   title: "Oupss",
        //   text:
        //     "Impossible de déterminer votre position gps. Veuillez vérifier que votre GPS est activé"
        // });
      }
    }

    async function launchPaymentProcess(
      price?: number
    ): Promise<ApprovedTransaction> {
      const FedaPay = (window as any).FedaPay;

      try {
        const completedTransactionId = await new Promise((resolve, reject) => {
          const { currentUser } = store.state.users;

          if (!currentUser) return reject("No logged user found");

          const initOptions = {
            public_key: fedaPayPublicKey,
            transaction: {
              amount: price ?? state.subscriptionPriceDetails.price,
              description: "Frais d'abonnement",
            },
            currency: {
              iso: "XOF",
            },
            customer: {
              firstname: currentUser.name ?? "",
              lastname: currentUser.firstName ?? "",
              phone_number: {
                country: "BJ",
              },
            },
            onComplete: (result: { reason: string; transaction: any }) => {
              if (result.reason === "CHECKOUT COMPLETE") {
                resolve(result.transaction.id);
              } else {
                reject(result.reason);
              }
            },
          };

          FedaPay.init(initOptions).open();
        });

        const paymentReallyCompleted =
          await store.dispatch.common.verifyPayment(
            completedTransactionId as number
          );

        if (!paymentReallyCompleted)
          throw new Error("Transaction not completed");

        return paymentReallyCompleted;
      } catch (error) {
        return {
          approved: false,
        };
      }
    }

    async function uploadPictures() {
      if (!vueDropzone) return;
      (vueDropzone.value as any).processQueue();

      try {
        await new Promise((resolve, reject) => {
          const interval = setInterval(() => {
            if (state.uploadComplete) {
              resolve(state.uploadedPictureGalleryId);
              clearInterval(interval);
              return;
            }
            if (state.uploadFailed) {
              clearInterval(interval);
              reject("Upload failed");
            }
          }, 1000);
        });
        return true;
      } catch (error) {
        return false;
      }
    }

    async function addShop() {
      if (state.isLoading) return;
      state.isLoading = true;
      if ((vueDropzone.value as any).getAcceptedFiles().length < 1) {
        // context.root.$notify({
        //   group: "addProcessNotifs",
        //   type: "warning",
        //   title: "Oupss",
        //   text: "Veuillez fournir au moins une photo"
        // });

        Swal.fire({
          title: "Oupss",
          text: "Veuillez fournir au moins une photo",
          icon: "warning",
          confirmButtonText: "OK",
        });
        state.isLoading = false;
        return;
      }

      state.currentProcessMessage = "Téléchargement de vos photos";
      const pictureUploaded = await uploadPictures();
      state.currentProcessMessage = "Téléchargement terminé";

      if (!pictureUploaded) {
        // context.root.$notify({
        //   group: "addProcessNotifs",
        //   type: "error",
        //   title: "Oupss",
        //   text: "Une erreur est survenue lors de l'envoi des fichiers"
        // });
        Swal.fire({
          title: "Oupss",
          text: "Une erreur est survenue lors de l'envoi des fichiers",
          icon: "warning",
          confirmButtonText: "OK",
        });
        state.isLoading = false;
        state.currentProcessMessage = "AJOUTER";
        return;
      }

      if (!state.listingInfo.gpsLocation) {
        // context.root.$notify({
        //   group: "addProcessNotifs",
        //   type: "warning",
        //   title: "Oupss",
        //   text: "Veuillez fournir votre localisation gps"
        // });
        Swal.fire({
          title: "Oupss",
          text: "Veuillez fournir votre localisation gps",
          icon: "warning",
          confirmButtonText: "OK",
        });
        state.isLoading = false;
        state.currentProcessMessage = "AJOUTER";
        return;
      }

      state.currentProcessMessage = "En attente du paiement";
      const paymentResult = await launchPaymentProcess();
      console.log("paymentResult:", paymentResult);

      if (
        !paymentResult.approved ||
        !paymentResult.amount
      ) {
        Swal.fire({
          title: "Oupss",
          text: "La transaction n'a pas été complétée",
          icon: "warning",
          confirmButtonText: "OK",
        });
        state.isLoading = false;
        state.currentProcessMessage = "AJOUTER";
        return;
      }

      state.subscriptionDetails.transactionId = paymentResult.transactionId;

      const payload: GqlAddShopInput = {
        name: state.listingInfo.name,
        cityId: state.listingInfo.cityId!.toString(),
        categoryId: state.listingInfo.categoryId!.toString(),
        contact1: state.listingInfo.phone1,
        contact2: state.listingInfo.phone2,
        galleryId: state.uploadedPictureGalleryId!.toString(),
        location: state.listingInfo.gpsLocation as GqlGpsLocationInput,
        description: state.listingInfo.description,
        subscriptionDetails: state.subscriptionDetails,
      };

      const shopInsertResult = await store.dispatch.users.addShop(payload);

      if (!shopInsertResult.success) {
        Swal.fire({
          title: "Erreur",
          text: shopInsertResult.message,
          icon: "error",
          confirmButtonText: "OK",
        });
        state.isLoading = false;
        state.currentProcessMessage = "AJOUTER";
      } else {
        await Swal.fire({
          title: "Terminé",
          text: shopInsertResult.message,
          icon: "success",
          confirmButtonText: "OK",
        });
        router.replace("/mon-profil");
      }
    }

    function onMultiUploadComplete(files: any, response: any) {
      state.uploadComplete = true;
      state.uploadedPictureGalleryId = response.galleryId;
    }

    function onMultiUploadError(files: any, message: string, xhr: any) {
      state.uploadFailed = true;
      console.error(message);
    }

    function onPhone1InputUpdate(eventParams: any) {
      state.validator.isPhone1NumberValid = eventParams.isValid;
    }

    function onPhone2InputUpdate(eventParams: any) {
      state.validator.isPhone2NumberValid = eventParams.isValid;
    }

    function onSubscriptionPriceDetailsChange(
      priceDetails: SubscriptionPriceDetails
    ) {
      state.subscriptionPriceDetails = priceDetails;
    }

    function testFedapay() {
      launchPaymentProcess(100);
    }

    return {
      state,
      phoneInputOptions,
      dropzoneOptions,
      locateMe,
      launchPaymentProcess,
      vueDropzone,
      onMultiUploadComplete,
      onMultiUploadError,
      addShop,
      onPhone1InputUpdate,
      onPhone2InputUpdate,
      phone1input,
      phone2input,
      onSubscriptionPriceDetailsChange,
      testFedapay,
    };
  },
});
