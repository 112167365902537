import router from "@/router";
import store from "@/store";
import axios from "axios";

export interface RequestOptions {
  query: string;
  variables: object;
  authToken?: string;
}

interface RawBackendError {
  name: string;
  message: string;
}

export class ErrorFromBackend extends Error {
  constructor(message: string, readonly httpStatus: number) {
    super(message);
  }
}

const graphqlUrl: string = process.env.VUE_APP_BACKEND_API_GRAPHQL_URL;

export async function graphqlRequest({
  query,
  variables,
  authToken
}: RequestOptions) {
  try {
    const options: any = {
      method: "post",
      url: graphqlUrl,
      data: { query, variables }
    };

    if (authToken) options.headers = { authorization: `Bearer ${authToken}` };
    const resp = await axios(options);
    console.log("resp.data.errors:", resp.data.errors);
    if (resp.data.errors) throw new Error(JSON.stringify(resp.data.errors));
    return resp.data.data;
  } catch (err) {
    const typedError = err as any;
    let parsedError: RawBackendError[];

    if (typeof typedError.message === "object")
      parsedError = JSON.parse(typedError.message) as RawBackendError[];
    else parsedError = [{ message: typedError.message, name: typedError.name }];

    const mainError = parsedError[0];

    let errorToReturn: ErrorFromBackend;

    switch (mainError.message) {
      case "An authentication error occured: Missing access token":
        errorToReturn = new ErrorFromBackend(mainError.message, 400);
        break;

      case "Request failed with status code 400":
        errorToReturn = new ErrorFromBackend(mainError.message, 400);
        break;

      default:
        errorToReturn = new ErrorFromBackend(mainError.message, 500);
        break;
    }

    console.log("errorToReturn:", errorToReturn.message);

    if (errorToReturn.httpStatus === 401) {
      store.dispatch.users.logout();
      router.push("/login");
    }


    throw errorToReturn;

    console.log("parsedError:", parsedError);
    // const resp = err.response;
    // if (!resp) throw err;
    // const graphqlErrors = resp.data && resp.data.errors;
    // if (graphqlErrors)
    //   throw new ErrorFromBackend(
    //     graphqlErrors.map((error: any) => error.message).join("\n"),
    //     resp.status
    //   );
    // else if (typeof resp.data === "string")
    //   throw new ErrorFromBackend(resp.data, resp.status);
    // throw new ErrorFromBackend(err.message, resp.status);
  }
}
