



































































import router from "@/router/index";
import store from "@/store/index";
import {
  computed,
  defineComponent,
  onMounted,
  reactive
} from "@vue/composition-api";
import { Menu, menus, subMenuItemsMaps } from "./menu-items";
export default defineComponent({
  name: "AppMenu",
  setup(_, context) {
    const state = reactive({
      menuItems: menus,
      selectedMenu: null as Menu | null,
      selectedSubMenu: null as Menu | null,
      showMenu: true,
      loggedUser: computed(
        (): boolean => store.state.users.currentUser !== null
      )
    });

    function menuClick(value: Menu) {
      // alert("+++++++++++");
      if (state.selectedMenu?.name == value.name) {
        state.selectedMenu = null;
      } else {
        state.selectedMenu = value;
      }

      if (value.type === "link") {
        context.emit("menuClicked");
      }
    }

    function subMenuClick(value: Menu) {
      if (state.selectedSubMenu?.name == value.name) {
        state.selectedSubMenu = null;
      } else {
        state.selectedSubMenu = value;
      }
      if (value.type === "link") {
        context.emit("menuClicked");
      }
    }

    function menuButtonClick(item: Menu) {
      if (item.name === "Déconnexion") {
        store.dispatch.users.logout();
        router.push("/accueil");
      }
    }

    function canBeShown(menu: Menu) {
      if (state.loggedUser) {
        return [
          subMenuItemsMaps["Déconnexion"],
          subMenuItemsMaps["Mot de passe oublié"]
        ].includes(menu.state);
      } else {
        return [
          subMenuItemsMaps["Connexion"],
          subMenuItemsMaps["Inscription"]
        ].includes(menu.state);
      }
    }

    return {
      state,
      canBeShown,
      menuClick,
      subMenuClick,
      menuButtonClick
    };
  }
});
