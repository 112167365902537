<template>
  <div class="block-space bg-primary">
    <div class="container">
      <div class="block-head text-center mb-5">
        <h2 class="head-line text-white display-3">
          {{ title }}
        </h2>
        <div class="col-12 col-md-7 mx-auto">
          <p class="lead mt-2 head-desc text-white">{{ desc }}</p>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row row-grid mt-5">
        <div class="col-lg-4 text-center" v-for="service in data" :key="service.title">
          <div
            class="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary"
          >
            <i :class="service.icon"></i>
          </div>
          <h5 class="text-white mt-3">{{ service.title }}</h5>
          <p class="text-white mt-3">{{ service.desc }}</p>
        </div>
      </div>
    </div>
    <div class="text-center mt-5">
      <a href=""
        ><img src="/static/images/playstore-badge.png" alt="Télécharger sur le playstore"
      /></a>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "desc", "data"],
};
</script>
