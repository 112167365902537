var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"style-1",attrs:{"id":"navigation"}},[_c('ul',{attrs:{"id":"responsive"}},_vm._l((_vm.state.menuItems),function(menuitem){return _c('li',{key:menuitem.name,class:{
        'open-menu':
          _vm.state.selectedMenu && _vm.state.selectedMenu.name == menuitem.name
      }},[(menuitem.type === 'link')?_c('router-link',{staticClass:"current",attrs:{"to":menuitem.state},nativeOn:{"click":function($event){return _vm.menuClick(menuitem)}}},[_vm._v(_vm._s(menuitem.name))]):_vm._e(),(menuitem.type === 'sub')?_c('a',{staticClass:"current",on:{"click":function($event){return _vm.menuClick(menuitem)}}},[_vm._v(_vm._s(menuitem.name))]):_vm._e(),(menuitem.type === 'sub' && menuitem.children)?_c('ul',_vm._l((menuitem.children),function(childitem){return _c('li',{key:childitem.name,class:{
            'open-menu':
              _vm.state.selectedSubMenu &&
              _vm.state.selectedSubMenu.name == childitem.name
          }},[(childitem.type === 'link' && _vm.canBeShown(childitem))?_c('router-link',{attrs:{"to":childitem.state}},[_vm._v(_vm._s(childitem.name))]):_vm._e(),(childitem.type === 'sub' && _vm.canBeShown(childitem))?_c('a',{on:{"click":function($event){return _vm.subMenuClick(childitem)}}},[_vm._v(_vm._s(childitem.name))]):_vm._e(),(childitem.type === 'button' && _vm.canBeShown(childitem))?_c('a',{on:{"click":function($event){return _vm.menuButtonClick(childitem)}}},[_vm._v(_vm._s(childitem.name))]):_vm._e(),(childitem.type === 'sub' && childitem.children)?_c('ul',_vm._l((childitem.children),function(subchilditem){return _c('li',{key:subchilditem.name},[_c('router-link',{attrs:{"to":subchilditem.state}},[_vm._v(_vm._s(subchilditem.name))])],1)}),0):_vm._e()],1)}),0):_vm._e()],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }