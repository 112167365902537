







































import { PromotionPriceDetails } from "@/helpers/types";
import store from "@/store";
import {
  computed,
  defineComponent,
  reactive,
  watch
} from "@vue/composition-api";

export default defineComponent({
  name: "PromotionDurationInput",
  props: {
    label: {
      type: String,
      required: true
    }
  },
  setup(props, context) {
    const state = reactive({
      multiplier: 1,
      detailsText: computed(
        (): string =>
          `${state.multiplier} jour${
            state.multiplier > 1 ? "s" : ""
          } (${state.currentSubscriptionPrice * state.multiplier} FCFA)`
      ),
      canReduceMultiplier: computed((): boolean => state.multiplier > 1),
      currentSubscriptionPrice: computed(
        (): number => store.state.common.platformSetup?.promotionDailyPrice ?? 0
      )
    });

    watch(() => state.multiplier, onSubscriptionPriceDetailsChange);

    function onSubscriptionPriceDetailsChange() {
      context.emit("promotionPriceDetailsChange", {
        dayCount: state.multiplier,
        price: state.multiplier * state.currentSubscriptionPrice
      } as PromotionPriceDetails);
    }

    return {
      props,
      state
    };
  }
});
