


















































































































































import store from "@/store";
import { Subscription, Transaction } from "@/store/user";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref
} from "@vue/composition-api";
import { TransactionStatus } from "shared-dependancies";
import Avatar from "vue-avatar";
import Vodal from "vodal";
import { ApprovedTransaction, SubscriptionPriceDetails } from "@/helpers/types";
import SubscriptionInput from "@/components/Forms/SubscriptionInput.vue";
import { format } from "date-fns/esm";
import { addDays } from "date-fns";

const fedaPayPublicKey = process.env.VUE_APP_FEDAPAY_PUBLIC_KEY;

export default defineComponent({
  name: "Transaction",
  props: {},
  components: {
    Avatar,
    Vodal,
    SubscriptionInput
  },
  setup(props, context) {
    const startDate = ref(
      computed(
        (): Date => {
          if (
            store.state.users.currentUser &&
            store.state.users.currentUser.activeSubscription
          ) {
            const activeSubscriptionEndDate =
              store.state.users.currentUser?.activeSubscription?.endDate;
            const reversedDate = activeSubscriptionEndDate
              .split("-")
              .reverse()
              .join("-");

            return new Date(reversedDate);
          } else {
            return new Date();
          }
        }
      )
    );

    const state = reactive({
      userTransactions: computed(
        (): Transaction[] => store.state.users.currentUser?.transactions ?? []
      ),
      userActiveSubscription: computed(
        (): Subscription | null | undefined =>
          store.state.users.currentUser?.activeSubscription
      ),
      showUpdateSubscriptionModal: false,
      isSendingUpdateSubscriptionRequest: false,
      subscriptionPriceDetails: {
        monthCount: 1,
        price: computed(
          (): number => store.state.common.platformSetup?.monthlyFee ?? 0
        )
      } as SubscriptionPriceDetails,
      subscriptionDetails: {
        startDate: format(startDate.value, "dd-MM-yyyy") as string,
        endDate: computed((): string =>
          format(
            addDays(
              startDate.value,
              state.subscriptionPriceDetails.monthCount * 30
            ),
            "dd-MM-yyyy"
          )
        ),
        price: 0 as number
      }
    });

    function onSubscriptionUpdateDetailsChange(
      priceDetails: SubscriptionPriceDetails
    ) {
      state.subscriptionPriceDetails = priceDetails;
    }

    async function updateUserSubscription() {
      if (state.isSendingUpdateSubscriptionRequest) return;
      state.isSendingUpdateSubscriptionRequest = true;
      const paymentResult = await launchPaymentProcess();

      if (paymentResult.completed && paymentResult.transactionId) {
        const transactionUpdatePayload = {
          startDate: state.subscriptionDetails.startDate,
          endDate: state.subscriptionDetails.endDate,
          transactionId: paymentResult.transactionId.toString()
        };

        const result = await store.dispatch.users.sendSubscriptionUpdateRequest(
          transactionUpdatePayload
        );

        if (result.success) {
          await store.dispatch.users.fetchUserDetails();
          state.showUpdateSubscriptionModal = false;
          state.isSendingUpdateSubscriptionRequest = false;
        }
        state.isSendingUpdateSubscriptionRequest = false;
      } else {
        state.isSendingUpdateSubscriptionRequest = false;
      }
    }

    async function launchPaymentProcess() {
      const FedaPay = (window as any).FedaPay;

      try {
        const completedTransactionId = await new Promise((resolve, reject) => {
          const { currentUser } = store.state.users;

          if (!currentUser) return reject("No logged user found");

          const initOptions = {
            public_key: fedaPayPublicKey,
            transaction: {
              amount: state.subscriptionPriceDetails.price,
              description: "Frais de renouvellement d'abonnement"
            },
            currency: {
              iso: "XOF"
            },
            customer: {
              firstname: currentUser.name ?? "",
              lastname: currentUser.firstName ?? "",
              phone_number: {
                country: "BJ"
              }
            },
            onComplete: (result: { reason: string; transaction: any }) => {
              if (result.reason === "CHECKOUT COMPLETE") {
                resolve(result.transaction.id);
              } else {
                reject(result.reason);
              }
            }
          };

          FedaPay.init(initOptions).open();
        });

        if (typeof completedTransactionId === "number")
          return {
            completed: true,
            transactionId: completedTransactionId
          };
        return {
          completed: false
        };
      } catch (error) {
        return {
          completed: false
        };
      }
    }

    function transactionStatusToUserReadable(status: TransactionStatus) {
      const statusLabel = {
        Pending: "En cours",
        Failed: "Echec",
        Completed: "Achevée",
        Cancelled: "Annulé"
      };

      return statusLabel[status];
    }

    return {
      state,
      transactionStatusToUserReadable,
      onSubscriptionUpdateDetailsChange,
      updateUserSubscription
    };
  }
});
