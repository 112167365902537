import Vue from "vue";
import Router from "vue-router";

import store from "@/store";
import clientRoutes from "./client";

// session components
const SignUp = () => import("../views/session/SignUp.vue");
const Login = () => import("../views/session/Login.vue");

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    clientRoutes,
    {
      path: "/register",
      component: SignUp,
      props: (route: any) => ({
        referrerCode: route.query["referrer"]
      }),
      meta: {
        title: "Signup"
        // breadcrumb: 'Session / Sign Up'
      }
    },
    {
      path: "/session/login",
      component: Login,
      meta: {
        title: "Login"
        // breadcrumb: 'Session / Login'
      }
    }
  ]
});

const restrictedAccessRoutes = ["UserProfile"];

router.beforeEach(async (to, from, next) => {
  store.dispatch.common.updateAccessTokenAvailablity();

  if (
    to.name &&
    isRestrictedComponent(to.name) &&
    !store.state.common.accessTokenAvailable
  ) {
    router.push("/session/login");
    return;
  }
  next();
});

function isRestrictedComponent(componentName: string): boolean {
  return restrictedAccessRoutes.includes(componentName);
}

export default router;
