














































import router from "@/router";
import store from "@/store";
import { CategoryState } from "@/store/common";
import {
  computed,
  defineComponent,
  onMounted,
  reactive
} from "@vue/composition-api";
import Slick from "vue-slick";

export default defineComponent({
  name: "PopularCategories",
  props: {
    title: {
      required: true,
      type: String
    },
    desc: {
      required: true,
      type: String
    },
    data: {
      required: true
    }
  },
  components: {
    Slick
  },
  setup(props) {
    const state = reactive({
      slickOptions: computed(() => getSlickOptions()),
      categories: computed((): CategoryState[] => store.state.common.categories)
    });

    onMounted(() => {
      store.dispatch.common.fetchCategories();
    });

    function getSlickOptions() {
      return {
        centerMode: true,
        centerPadding: "15%",
        slidesToShow: 3,
        dots: true,
        arrows: false,
        responsive: [
          {
            breakpoint: 1441,
            settings: {
              centerPadding: "10%",
              slidesToShow: 3
            }
          },
          {
            breakpoint: 1025,
            settings: {
              centerPadding: "10px",
              slidesToShow: 2
            }
          },
          {
            breakpoint: 767,
            settings: {
              centerPadding: "10px",
              slidesToShow: 1
            }
          }
        ]
      };
    }

    function getRandomItem() {
      const randomIndex = Math.floor(
        Math.random() * (props as any).data.length
      );

      return (props as any).data[randomIndex];
    }

    function goToAppWithCategory(categoryId: string) {
      void router.push(`/artisans-a-proximite?categoryFilter=${categoryId}`);
    }

    return {
      state,
      props,
      getRandomItem,
      goToAppWithCategory
    };
  }
});
