















































































































































































import {
  computed,
  defineComponent,
  onMounted,
  reactive
} from "@vue/composition-api";
import Avatar from "vue-avatar";
import Vodal from "vodal";
import { Referral, Stats } from "@/store/user";
import store from "@/store";
import { Clipboard } from "@capacitor/core";
import { getPhoneInputOptions } from "@/helpers/phone-input";
import VuePhoneNumberInput from "vue-phone-number-input";
import TransferCanalChooser from "../Forms/TransferCanalChooser.vue";
import { GqlWithdrawRequestInput } from "@/helpers/gql-schema";
import { TransferCanalType } from "shared-dependancies";
import Swal from "sweetalert2";

function defaultWithdrawalRequestInfo() {
  return {
    minAmount: store.state.common.platformSetup?.minimumAmountToWithdral ?? 0,
    recipientNumber: "",
    recipientNumberConfirmation: "",
    isPhoneNumberValid: false,
    isConfirmationPhoneNumberValid: false,
    amount: 0,
    transferCanal: "Momo" as TransferCanalType
  };
}

export default defineComponent({
  name: "Referrals",
  components: {
    Avatar,
    Vodal,
    VuePhoneNumberInput,
    TransferCanalChooser
  },
  setup(_props, context) {
    const phoneInputOptions = getPhoneInputOptions();

    const state = reactive({
      showWithdrawlModal: false,
      userReferrals: computed(
        (): Referral[] => store.state.users.currentUser?.referrals ?? []
      ),
      userReferrerCode: computed(
        (): string | undefined =>
          store.state.users.currentUser?.account?.referralCode
      ),
      userStats: computed((): Stats | undefined =>
        store.state.users.currentUser && store.state.users.currentUser
          ? (store.state.users.currentUser.stats as Stats)
          : undefined
      ),
      canWithdral: computed((): boolean =>
        state.userStats === undefined
          ? false
          : state.userStats.availableFund >
              store.state.common.platformSetup!.minimumAmountToWithdral! ?? 0
      ),
      withdrawalRequestInfo: defaultWithdrawalRequestInfo(),
      showErrorMessage: false,
      amountError: computed((): string => {
        if (!state.showErrorMessage) return "";
        if (
          state.withdrawalRequestInfo.amount < 0 ||
          state.withdrawalRequestInfo.amount <
            state.withdrawalRequestInfo.minAmount
        )
          return "Montant invalide";
        return "";
      }),
      canSendWithdralRequest: computed(
        (): boolean =>
          state.withdrawalRequestInfo.isPhoneNumberValid &&
          state.withdrawalRequestInfo.isConfirmationPhoneNumberValid &&
          state.withdrawalRequestInfo.recipientNumber ===
            state.withdrawalRequestInfo.recipientNumberConfirmation &&
          state.withdrawalRequestInfo.amount >=
            store.state.common.platformSetup!.minimumAmountToWithdral! &&
          state.userStats !== undefined &&
          state.withdrawalRequestInfo.amount <= state.userStats.availableFund //TODO: Remove before going into prod
      ),
      isSendingWithdrawalRequest: false,
      platformFrontendUrl: computed(
        (): string => store.state.common.platformSetup.frontendUrl ?? ""
      )
    });

    function showWithdrawlModal() {
      if (!state.canWithdral) {
        Swal.fire({
          title: "Information",
          text: `Solde insuffisant pour effectuer un retrait (${
            store.state.common.platformSetup!.minimumAmountToWithdral
          } FCFA minimum)`,
          icon: "error",
          confirmButtonText: "OK"
        });
        return;
      }

      state.showWithdrawlModal = true;
    }

    function onTransferCanalChange(value: TransferCanalType) {
      state.withdrawalRequestInfo.transferCanal = value;
    }

    async function copyInviteLink() {
      await Clipboard.write({
        string: `${state.platformFrontendUrl}register?referrer=${state.userReferrerCode}`
      });
      context.emit("inviteLinkCopied");
    }

    async function sendWithdralRequest() {
      if (!state.withdrawalRequestInfo.transferCanal) return;

      state.isSendingWithdrawalRequest = true;

      const payload: GqlWithdrawRequestInput = {
        amount: state.withdrawalRequestInfo.amount,
        receiverPhoneNumber: state.withdrawalRequestInfo.recipientNumber,
        transferCanal: state.withdrawalRequestInfo.transferCanal
      };

      const result = await store.dispatch.users.sendWithDrawRequest(payload);

      if (result.success) {
        await store.dispatch.users.fetchUserStatsAndTransaction();
        state.withdrawalRequestInfo = defaultWithdrawalRequestInfo();
        state.showWithdrawlModal = false;
        state.isSendingWithdrawalRequest = false;
        Swal.fire({
          title: "Information",
          text: result.message,
          icon: "success",
          confirmButtonText: "OK"
        });
      }
    }

    function onPhoneInputUpdate(eventParams: any) {
      state.withdrawalRequestInfo.isPhoneNumberValid = eventParams.isValid;
    }

    function onPhoneConfirmationInputUpdate(eventParams: any) {
      state.withdrawalRequestInfo.isConfirmationPhoneNumberValid =
        eventParams.isValid;
    }

    return {
      state,
      showWithdrawlModal,
      copyInviteLink,
      phoneInputOptions,
      onPhoneInputUpdate,
      onPhoneConfirmationInputUpdate,
      onTransferCanalChange,
      sendWithdralRequest
    };
  }
});
