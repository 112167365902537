import router from "@/router";
import axios, { AxiosRequestConfig } from "axios";
import { getToken } from ".";

type AxiosMethod =
  | "GET"
  | "DELETE"
  | "HEAD"
  | "OPTIONS"
  | "POST"
  | "PUT"
  | "PATCH"
  | "LINK"
  | "UNLINK"
  | undefined;

export async function apiRequest(
  method: AxiosMethod,
  endPoint: string,
  variables?: any,
  formData?: boolean
) {
  let authToken: string | undefined | null = undefined;

  authToken = getToken();

  try {
    const options: AxiosRequestConfig = {
      method,
      url: `${process.env.VUE_APP_BACKEND_API_URL}/${endPoint}`,
      data: variables,
      headers: {}
    };

    if (authToken) options.headers["authorization"] = `Bearer ${authToken}`;

    if (formData)
      options.headers["Content-Type"] = "application/x-www-form-urlencoded";

    const resp = await axios(options);

    if (!resp) throw new Error("Error from API: " + resp);

    return resp;
  } catch (err) {
    console.error("[ERROR]:", err);
    const resp = (err as any).response;
    if (resp && resp.status === 401) {
      localStorage.removeItem("accessToken");
      router.push("session/login");
    } else {
      throw err;
    }
    /* const resp = err.response;
    if (!resp)
      throw err;
    const graphqlErrors = resp.data && resp.data.errors;
    if (graphqlErrors)
      throw new Error(graphqlErrors.map(error => error.message).join("\n"));
    if (typeof resp.data === "string")
      throw new Error(`HTTP Status ${resp.status} (${resp.statusText}), ${resp.data}`);
    throw err; */
  }
}
